/**
 * Recent documents dashboard component
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchActionCreators } from '../../Search';

/**
 * Office Fabric UI components
 */
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

/**
 * GovTeams dependencies
 */
import * as constants from '../../../common/Constants';

export class RecentDocuments extends React.Component<any, any> {
  private myDocumentColumns = [
    {
      fieldName: 'fileType',
      isResizable: true,
      key: 'fileType',
      maxWidth: 60,
      minWidth: 60,
      name: 'File type'
    },
    {
      fieldName: 'title',
      isMultiline: true,
      isResizable: true,
      key: 'title',
      minWidth: 300,
      name: 'Title'
    },
    {
      fieldName: 'lastAccessed',
      isResizable: true,
      key: 'lastAccessed',
      maxWidth: 170,
      minWidth: 170,
      name: 'Date accessed'
    },
    {
      fieldName: 'lastModified',
      isResizable: true,
      key: 'lastModified',
      maxWidth: 170,
      minWidth: 170,
      name: 'Date modified'
    },
    {
      fieldName: 'community',
      isMultiline: true,
      isResizable: true,
      key: 'community',
      minWidth: 170,
      name: 'Commmunity'
    }
  ];

  private popularDocumentsColumns = [
    {
      fieldName: 'fileType',
      isResizable: true,
      key: 'fileType',
      maxWidth: 60,
      minWidth: 60,
      name: 'File type'
    },
    {
      fieldName: 'title',
      isMultiline: true,
      isResizable: true,
      key: 'title',
      name: 'Title'
    },
    {
      fieldName: 'community',
      isMultiline: true,
      isResizable: true,
      key: 'community',
      minWidth: 170,
      name: 'Commmunity'
    }
  ];

  constructor(props: any) {
    super(props);

    this.state = { columns: [], selectedKey: constants.RECENT_DOCUMENTS_MY };
  }

  public render() {
    return (
      <div className='govTeams-recentDocuments'>
        <h2>Recent documents</h2>
        <div className='pivotLinks'>
          <Pivot
            selectedKey={this.state.selectedKey}
            onLinkClick={this.onPivotLinkClick}
          >
            <PivotItem
              ariaLabel={'Documents you have recently contributed to.'}
              headerText={constants.RECENT_DOCUMENTS_MY}
              itemKey={constants.RECENT_DOCUMENTS_MY}
              onRenderItemLink={this.onRenderItemLink}
            />
            <PivotItem
              ariaLabel={'You only see documents you have access to.'}
              headerText={constants.RECENT_DOCUMENTS_POPULAR}
              itemKey={constants.RECENT_DOCUMENTS_POPULAR}
              onRenderItemLink={this.onRenderItemLink}
            />
          </Pivot>
        </div>
        <div>
          {this.props.recentDocuments &&
            this.props.recentDocuments.length > 0 && (
              <DetailsList
                columns={this.state.columns}
                constrainMode={ConstrainMode.horizontalConstrained}
                items={this.props.recentDocuments}
                layoutMode={DetailsListLayoutMode.justified}
                onRenderItemColumn={this.onRenderItem}
                selectionMode={SelectionMode.none}
              />
            )}
          {this.props.recentDocuments &&
            this.props.recentDocuments.length === 0 && (
              <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={true}
              >
                There are no results for{' '}
                {this.state.selectedKey === constants.RECENT_DOCUMENTS_MY
                  ? constants.RECENT_DOCUMENTS_MY
                  : constants.RECENT_DOCUMENTS_POPULAR}
              </MessageBar>
            )}
        </div>
      </div>
    );
  }

  public componentDidMount(): void {
    this.setState({ columns: this.myDocumentColumns });
  }

  private onRenderItemLink = (el: any) => {
    return (
      <TooltipHost
        content={el.ariaLabel}
        directionalHint={DirectionalHint.bottomCenter}
      >
        {el.headerText}
      </TooltipHost>
    );
  };

  private onRenderItem = (item: any, index: number, column: any) => {
    const fieldContent = item[column.fieldName];
    const key = column.key;

    switch (key) {
      case 'title':
        return this.onRenderTitle(item);
      case 'fileType':
        return this.onRenderFileType(item.type);
      case 'dateModified':
        const date = new Date(fieldContent);
        return date.toLocaleString('en-US');
      default:
        return fieldContent;
    }
  };

  private onRenderTitle(item: any) {
    let previewText = item.previewText;

    if (previewText.length > 90) {
      previewText = previewText.substring(0, 90) + '...';
    }

    return (
      <div>
        <strong>
          <Link href={item.url} target='_blank'>
            {item.title}
          </Link>
        </strong>
        <div>{previewText}</div>
      </div>
    );
  }

  private onRenderFileType = (fileType: string) => {
    let extension = '';

    switch (fileType) {
      case 'Word':
        extension = 'docx';
        break;
      case 'OneNote':
        extension = 'one';
        break;
      case 'OneNotePage':
        extension = 'one';
        break;
      case 'Web':
        extension = 'website';
        break;
      case 'Excel':
        extension = 'xlsx';
        break;
      case 'Csv':
        extension = 'csv';
        break;
      case 'PowerPoint':
        extension = 'csv';
        break;
      case 'Pdf':
        extension = 'pdf';
        break;
      case 'Text':
        extension = 'txt';
        break;
      case 'spsite':
        extension = 'website';
        break;
      default:
        extension = '';
        break;
    }

    return <Icon {...getFileTypeIconProps({ extension, size: 32 })} />;
  };

  private onPivotLinkClick = (item: any) => {
    this.setState({ selectedKey: item.props.itemKey });

    switch (item.props.itemKey) {
      case constants.RECENT_DOCUMENTS_MY:
        this.setState({ columns: this.myDocumentColumns });

        this.props.search.requestFetchRecentDocuments(
          constants.RECENT_DOCUMENTS_MY
        );
        break;

      case constants.RECENT_DOCUMENTS_POPULAR:
        this.setState({ columns: this.popularDocumentsColumns });
        this.props.search.requestFetchRecentDocuments(
          constants.RECENT_DOCUMENTS_POPULAR
        );
        break;
      default:
        break;
    }
  };
}

const mapStateToProps = (state: any) => ({
  recentDocuments: state.search.recentDocuments
});

const mapDispatchToProps = (dispatch: any) => ({
  search: bindActionCreators(searchActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentDocuments);
