import { actions } from './Organisation.actions';
import { IOrganisationState } from './Organisation.types';

export const initialState: IOrganisationState = {
  organisation: undefined,
  organisations: undefined,
  domains: undefined,
  licence: undefined,
  admins: undefined,
  membersResult: undefined,
  membersReserved: '',
  membersBlocked: '',
  isLoading: false,
  isLoadingAll: false,
  isExporting: false,
  isFormSubmitted: false,
  organisationError: undefined,
  membersActionResult: undefined,
  membersCsv: undefined,
  membersError: undefined,
  message: undefined,
  error: undefined,
  memberForAction: undefined,
  membersRemoveActionResult: undefined
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ORG_FETCH_REQUEST: {
      return {
        ...state,
        organisation: undefined,
        domains: undefined,
        licence: undefined,
        admins: undefined,
        organisationError: undefined,
        isLoading: true
      };
    }

    case actions.ORG_FETCH_RESPONSE: {
      return {
        ...state,
        organisation: action.organisation,
        organisationError: action.organisationError,
        isLoading: false
      };
    }

    case actions.ORG_FETCH_MESSAGE_REQUEST: {
      return {
        ...state,
        message: undefined,
        isLoading: true
      };
    }

    case actions.ORG_FETCH_MESSAGE_RESPONSE: {
      return {
        ...state,
        message: action.message,
        isLoading: false
      };
    }

    case actions.ORG_SAVE_REQUEST: {
      return {
        ...state,
        organisationError: undefined,
        isFormSubmitted: false,
        isLoading: true
      };
    }

    case actions.ORG_SAVE_RESPONSE: {
      return {
        ...state,
        // organisation: action.organisation,
        organisationError: action.organisationError,
        isFormSubmitted: true,
        isLoading: false
      };
    }

    case actions.ORG_EDIT_FORM_RESET: {
      return {
        ...state,
        organisationError: undefined,
        isLoading: false,
        isFormSubmitted: false
      };
    }

    case actions.ORG_FETCH_ALL_REQUEST: {
      return {
        ...state,
        isLoadingAll: true
      };
    }

    case actions.ORG_FETCH_ALL_RESPONSE: {
      return {
        ...state,
        organisations: action.organisations,
        error: action.error,
        isLoadingAll: false
      };
    }

    case actions.ORG_FETCH_ADMINS_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case actions.ORG_FETCH_ADMINS_RESPONSE: {
      return {
        ...state,
        admins: action.admins,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_FETCH_MEMBERS_REQUEST: {
      return {
        ...state,
        membersResult: undefined,
        membersError: undefined,
        isLoading: true
      };
    }

    case actions.ORG_FETCH_MEMBERS_RESPONSE: {
      return {
        ...state,
        membersResult: action.membersResult,
        membersError: action.membersError,
        isLoading: false
      };
    }

    case actions.ORG_DOMAINS_FETCH_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case actions.ORG_DOMAINS_FETCH_RESPONSE: {
      return {
        ...state,
        domains: action.domains,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_LICENCE_FETCH_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case actions.ORG_LICENCE_FETCH_RESPONSE: {
      return {
        ...state,
        licence: action.licence,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_MEMBERS_LICENCE_ALLOCATE_REQUEST: {
      return {
        ...state,
        membersActionResult: null,
        isLoading: true
      };
    }

    case actions.ORG_MEMBERS_LICENCE_ALLOCATE_RESPONSE: {
      return {
        ...state,
        membersActionResult: action.membersActionResult,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_MEMBERS_LICENCE_DEACTIVATE_REQUEST: {
      return {
        ...state,
        membersActionResult: null,
        isLoading: true
      };
    }

    case actions.ORG_MEMBERS_LICENCE_DEACTIVATE_RESPONSE: {
      return {
        ...state,
        membersActionResult: action.membersActionResult,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_MEMBERS_LICENCE_REMOVE_REQUEST: {
      return {
        ...state,
        membersActionResult: null,
        isLoading: true
      };
    }

    case actions.ORG_MEMBERS_LICENCE_REMOVE_RESPONSE: {
      return {
        ...state,
        membersActionResult: action.membersActionResult,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_MEMBERS_SEARCH_REQUEST: {
      return {
        ...state,
        membersActionResult: null,
        isLoading: true
      };
    }

    case actions.ORG_MEMBERS_SEARCH_RESPONSE: {
      return {
        ...state,
        membersResult: action.membersResult,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_MEMBERS_LICENCE_RESERVE_REQUEST: {
      return {
        ...state,
        membersActionResult: null,
        isLoading: true,
        isFormSubmitted: false
      };
    }

    case actions.ORG_MEMBERS_LICENCE_RESERVE_RESPONSE: {
      return {
        ...state,
        membersActionResult: action.membersActionResult,
        error: action.error,
        isLoading: false,
        isFormSubmitted: true
      };
    }

    case actions.ORG_MEMBERS_LICENCE_BLOCK_REQUEST: {
      return {
        ...state,
        membersActionResult: null,
        isLoading: true,
        isFormSubmitted: false
      };
    }

    case actions.ORG_MEMBERS_LICENCE_BLOCK_RESPONSE: {
      return {
        ...state,
        membersActionResult: action.membersActionResult,
        error: action.error,
        isLoading: false,
        isFormSubmitted: true
      };
    }

    case actions.ORG_MEMBERS_LICENCE_FORM_RESET: {
      return {
        ...state,
        membersResult: undefined,
        error: undefined,
        isLoading: false,
        isFormSubmitted: false
      };
    }

    case actions.ORG_MEMBERS_EXPORTCSV_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isExporting: true,
      };
    }

    case actions.ORG_MEMBERS_EXPORTCSV_RESPONSE: {
      return {
        ...state,
        isLoading: false,
        isExporting: false,
        membersCsv: action.membersCsv
      };
    }

    case actions.ORG_FETCH_MEMBERS_RESERVED_REQUEST: {
      return {
        ...state,
        membersReserved: '',
        isLoading: true
      };
    }

    case actions.ORG_FETCH_MEMBERS_RESERVED_RESPONSE: {
      return {
        ...state,
        membersReserved: action.membersReserved,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_FETCH_MEMBERS_BLOCKED_REQUEST: {
      return {
        ...state,
        membersBlocked: '',
        isLoading: true
      };
    }

    case actions.ORG_FETCH_MEMBERS_BLOCKED_RESPONSE: {
      return {
        ...state,
        membersBlocked: action.membersBlocked,
        error: action.error,
        isLoading: false
      };
    }

    case actions.ORG_FETCH_EXTERNAL_MEMBERS_REQUEST: {
      return {
        ...state,
        membersResult: undefined,
        membersError: undefined,
        isLoading: true
      };
    }
    
    case actions.ORG_FETCH_EXTERNAL_MEMBERS_RESPONSE: {
      return {
        ...state,
        membersResult: action.membersResult,
        membersError: action.membersError,
        isLoading: false
      };
    }

    case actions.ORG_FETCH_IP_RANGE_REQUEST: {
      return {
        ...state,
        ipRangeResult: undefined,
        ipRangeError: undefined,
        ipRangeMessage: undefined,
        isLoading: true
      };
    }

    case actions.ORG_FETCH_IP_RANGE_RESPONSE: {
      return {
        ...state,
        ipRangeResult: action.ipRangeResult,
        ipRangeError: action.ipRangeError,
        ipRangeMessage: action.ipRangeMessage,
        isLoading: false
      };
    }

    case actions.ORG_EXTERNAL_MEMBER_REVIEW_REQUEST: {
      return {
        ...state,
        membersActionResult: undefined,
        isLoading: true
      };
    }
    
    case actions.ORG_EXTERNAL_MEMBER_REVIEW_RESPONSE: {
      return {
        ...state,
        membersActionResult: action.membersActionResult,
        error: action.error,
        isLoading: false,
      };
    }

    case actions.ORG_EXTERNAL_MEMBER_REMOVE_REQUEST: {
      return {
        ...state,
        membersRemoveActionResult: undefined,
        isLoading: true
      };
    }

    case actions.ORG_EXTERNAL_MEMBER_REMOVE_RESPONSE: {
      return {
        ...state,
        membersActionResult: action.membersActionResult,
        error: action.error,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
