/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { profileActionCreators } from '../../controllers/ProfileController';
import { memberPreferencesActionCreators } from '../../controllers/MemberPreferencesController';

/**
 * Fabric UI
 */
import { ActionButton } from '@fluentui/react/lib/Button';
import { Link } from '@fluentui/react/lib/Link';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import {
  Persona,
  PersonaInitialsColor,
  PersonaSize
} from '@fluentui/react/lib/Persona';

/**
 * Constants and utilities
 */
import * as constants from '../../common/Constants';
//import { isProtected } from '../../common/Utils';
import { BRANDNAME } from '../../common/Tooltips';
import { isUserDashboardDisabled } from '../../auth';
import LicenceRestored from '../../screens/Dashboard/components/LicenceRestored/LicenceRestored';
import { MemberType } from '../../controllers/CommunityController';
import { saveDashboardInteractionEvent } from '../../common/EventTracking';

export class UserDisplay extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isProfileMenuVisible: false
    };
  }

  public render() {
    const { profile } = this.props;
    const isDashboardDisabled = isUserDashboardDisabled(this.props.profile);
    const personName = this.props.profile.displayName || `${profile.govTeamsProfileInfo?.firstName} ${profile.govTeamsProfileInfo?.lastName}`;

    return (
      <div className='govTeams-nav-persona'>
        {(!profile || !profile.govTeamsProfileInfo) ? (<a href={constants.SCREEN_DASHBOARD} className='nav-item-login'>Login</a>) : (
          <div>
            <div>
              <Persona
                className='govTeams-persona'
                title={personName}
                size={PersonaSize.size32}
                text={personName}
                secondaryText={this.props.profile.mail}
                initialsColor={PersonaInitialsColor.lightBlue}
                imageUrl={this.props.profile.imageUrl}
                onClick={this.onShowProfileMenu}
              />
              <LicenceRestored />
            </div>
            <Panel
              className='govTeams-panel govTeams-persona-panel'
              isOpen={this.state.isProfileMenuVisible}
              isBlocking={true}
              isLightDismiss={true}
              onDismiss={this.onPanelDismiss}
              customWidth='450px'
              type={PanelType.custom}
              isFooterAtBottom={true}
              onRenderFooterContent={this._renderPanelFooterContent}
              styles={{
                footer: { background: '#f8f8f8' }
              }}
            >
              <div>
                <div>
                  <div>
                    <h2 style={{ marginBottom: 10 }}>
                      {personName}
                    </h2>
                  </div>
                  <div>
                    {( profile.memberType === MemberType.FAM || profile.memberType === MemberType.Partner )
                      ? profile.azureMail
                      : profile.mail}
                  </div>
                </div>
                <ul>
                  <li>
                    <hr />
                  </li>
                  {!isDashboardDisabled && (
                    <li>
                      <Link onClick={this.showProfile}>
                        My {BRANDNAME} account
                      </Link>
                    </li>
                  )}
                  {!profile.isGuest && !isDashboardDisabled && (
                      <li>
                        <Link
                          href={
                            'https://aus.delve.office.com/?u=' +
                            profile.id +
                            '&v=work'
                          }
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          My Delve profile
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link href={constants.PROFILE_LINKPASSWORD} target='_blank'>
                      Change my password
                    </Link>
                  </li>
                  <li>
                    <Link href={constants.PROFILE_LINKMFA} target='_blank'>
                      Change multi-factor authentication option
                    </Link>
                  </li>
                  <li>
                    <Link href='#' onClick={this.showMemberPreferences}>
                      User preferences
                    </Link>
                  </li>
                </ul>
              </div>
              {profile.memberType === 'Organisation Admin' && !isDashboardDisabled && (
                  <div>
                    <br />
                    <h2>Manage Organisations</h2>
                    <ul>
                      <li>
                        <Link onClick={this.onShowGetAllMembers}>
                          Get all members
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              <hr />
              <ActionButton
                className='govTeams-actionButton icon-l'
                href={constants.SCREEN_LOGGING_OUT}
                iconProps={{ iconName: 'SignOut' }}
              >
                Sign out
              </ActionButton>
            </Panel>
          </div>
        )}
      </div>
    );
  }

  private _renderPanelFooterContent = () => {
    return (
      <div style={{ paddingBottom: 50 }}>
        {(this.props.profile.memberType === 'Full Access Member' || this.props.profile.memberType === 'Partner') && (
          <div>
            <div className='ms-Persona-primaryText'>Organisation contact</div>
            <div className='ms-Persona-secondaryText'>
              <a href={'mailto:' + this.props.profile.organisationContact}>
                {this.props.profile.organisationContact}
              </a>
            </div>
          </div>
        )}
      </div>
    );
  };

  private onShowProfileMenu = () => {
    this.setState({
      isProfileMenuVisible: !this.state.isProfileMenuVisible
    });
    saveDashboardInteractionEvent('profile_panel_clicked', 'profile');
  };

  private onPanelDismiss = () => {
    this.setState({
      isProfileMenuVisible: false
    });
  };

  private Logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    this.props.dispatch(this.props.profileActions.startLogoutProfile());
  };

  private showProfile = () => {
    this.setState({
      isProfileMenuVisible: false
    });
    this.props.dispatch(push(constants.SCREEN_PROFILE));
  };

  private onShowGetAllMembers = () => {
    this.setState({
      isProfileMenuVisible: false
    });
    this.props.dispatch(push(constants.SCREEN_ADMIN_ALLMEMBERS));
  };

  private showMemberPreferences = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.setState({
      isProfileMenuVisible: false
    });
    this.props.memberPreferencesActionCreators.showMemberPreferences();
  }
}

const mapStateToProps = (state: any) => ({
  profile: state.profile
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  profileActions: bindActionCreators(profileActionCreators, dispatch),
  memberPreferencesActionCreators: bindActionCreators(memberPreferencesActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDisplay);
