/**
 * Commmunity grid view
 *
 * Displays a four instances of the community grid for
 * -favourites
 * -owner
 * -member
 * -suggested
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from '../../../../controllers/CommunityController';
import { requestsActionCreators } from '../../../../controllers/RequestsController';

/**
 * UI Fabric
 */
import { ActionButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';

/**
 * GovTeams dependencies
 */
import CommunityGridSection from './CommunityGridSection';

import { isEmpty } from 'lodash';

export class CommunityGrid extends React.Component<any, any> {
  public static getDerivedStateFromProps(props) {
    return {
      favouriteItems: props.favouriteCommunities,
      memberItems: props.memberCommunities,
      ownerItems: props.ownerCommunities
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      expandFavouriteRow: false,
      expandMemberRow: false,
      expandOwnerRow: false,
      expandSuggestedRow: false,
      favouriteItems: [],
      memberItems: [],
      ownerItems: []
    };
  }

  public render() {
    return (
      <div className='communityGrid'>
        <React.Fragment>
          <div className='communityDashboard-section'>
            <div className='communityDashboard-sectionHeader'>
              <h3>
                Favourites{' '}
                {this.state.favouriteItems && (
                  <span className='heading-meta'>
                    ({this.state.favouriteItems.length})
                  </span>
                )}
              </h3>
              <div className='communityDashboard-sectionTools'>
                {!isEmpty(this.state.favouriteItems) && (
                  <ActionButton
                    className='communityAction'
                    onClick={(ev) => {
                      this.setState({
                        expandFavouriteRow: !this.state.expandFavouriteRow
                      });
                    }}
                    style={{
                      display: this.state.hideFavouriteExpandButton
                        ? 'none'
                        : 'block'
                    }}
                  >
                    {!this.state.expandFavouriteRow ? 'See more' : 'See less'}
                    <Icon
                      iconName={
                        !this.state.expandFavouriteRow
                          ? 'ChevronDown'
                          : 'ChevronUp'
                      }
                    />
                  </ActionButton>
                )}
              </div>
            </div>
            <div
              className={`community-ListGrid${
                this.state.expandFavouriteRow ? ' expand' : ''
              }${!isEmpty(this.state.favouriteItems) ? '' : ' noCommunities'}`}
            >
              {!this.props.isLoading && isEmpty(this.state.favouriteItems) && (
                <div className='communityDashboard-section-favourites'>
                  <div className='sectionText'>
                    Favourite communities by clicking the{' '}
                    <Icon iconName='FavoriteStar' /> icon.
                  </div>
                </div>
              )}
              <CommunityGridSection
                items={this.state.favouriteItems}
                isLoading={this.props.isLoading}
              />
            </div>
            <ActionButton
              className='communityAction'
              onClick={(ev) => {
                this.setState({
                  expandFavouriteRow: !this.state.expandFavouriteRow
                });
              }}
              style={{
                display: this.state.expandFavouriteRow ? 'block' : 'none'
              }}
            >
              See less <Icon iconName='ChevronUp' />
            </ActionButton>
            <hr />
          </div>

          {(this.props.isGuest == null || !this.props.isGuest) &&
            this.props.memberType !== 'Sponsored' &&
            this.state.ownerItems &&
            this.state.ownerItems.length !== 0 && (
              <div className='communityDashboard-section'>
                <div className='communityDashboard-sectionHeader'>
                  <h3>
                    Owner{' '}
                    {this.state.ownerItems && (
                      <span className='heading-meta'>
                        ({this.state.ownerItems.length})
                      </span>
                    )}
                  </h3>
                  <div className='communityDashboard-sectionTools'>
                    {!isEmpty(this.state.ownerItems) && (
                      <ActionButton
                        className='communityAction'
                        onClick={(ev) => {
                          this.setState({
                            expandOwnerRow: !this.state.expandOwnerRow
                          });
                        }}
                        style={{
                          display: this.state.hideOwnerExpandButton
                            ? 'none'
                            : 'block'
                        }}
                      >
                        {!this.state.expandOwnerRow ? 'See more' : 'See less'}
                        <Icon
                          iconName={
                            !this.state.expandOwnerRow
                              ? 'ChevronDown'
                              : 'ChevronUp'
                          }
                        />
                      </ActionButton>
                    )}
                  </div>
                </div>
                <div
                  className={`community-ListGrid${
                    this.state.expandOwnerRow ? ' expand' : ''
                  }`}
                >
                  <CommunityGridSection
                    items={this.state.ownerItems}
                    isLoading={this.props.isLoading}
                  />
                </div>
                <ActionButton
                  className='communityAction'
                  onClick={(ev) => {
                    this.setState({
                      expandOwnerRow: !this.state.expandOwnerRow
                    });
                  }}
                  style={{
                    display: this.state.expandOwnerRow ? 'block' : 'none'
                  }}
                >
                  See less <Icon iconName='ChevronUp' />
                </ActionButton>
                <hr />
              </div>
            )}

          <div className='communityDashboard-section'>
            <div className='communityDashboard-sectionHeader'>
              <h3>
                Member{' '}
                {this.state.memberItems && (
                  <span className='heading-meta'>
                    ({this.state.memberItems.length})
                  </span>
                )}
              </h3>
              <div className='communityDashboard-sectionTools'>
                {!isEmpty(this.state.memberItems) && (
                  <ActionButton
                    className='communityAction'
                    onClick={(ev) => {
                      this.setState({
                        expandMemberRow: !this.state.expandMemberRow
                      });
                    }}
                    style={{
                      display: this.state.hideMemberExpandButton
                        ? 'none'
                        : 'block'
                    }}
                  >
                    {!this.state.expandMemberRow ? 'See more' : 'See less'}
                    <Icon
                      iconName={
                        !this.state.expandMemberRow
                          ? 'ChevronDown'
                          : 'ChevronUp'
                      }
                    />
                  </ActionButton>
                )}
              </div>
            </div>
            <div
              className={`community-ListGrid${
                this.state.expandMemberRow ? ' expand' : ''
              }`}
            >
              <CommunityGridSection
                items={this.state.memberItems}
                isLoading={this.props.isLoading}
              />
            </div>
            <ActionButton
              className='communityAction'
              onClick={(ev) => {
                this.setState({
                  expandMemberRow: !this.state.expandMemberRow
                });
              }}
              style={{
                display: this.state.expandMemberRow ? 'block' : 'none'
              }}
            >
              See less <Icon iconName='ChevronUp' />
            </ActionButton>
          </div>
       
        </React.Fragment>
      </div>
    );
  }

  public async componentDidMount() {
    if (
      (this.props.ownerCommunities ||
        this.props.favouriteCommunities ||
        this.props.memberCommunities) &&
      !this.props.isDirty
    ) {
      const {
        favouriteCommunities,
        memberCommunities,
        ownerCommunities
      } = Object.assign({}, this.props);
      this.setState({
        favouriteItems: [...favouriteCommunities],
        memberItems: [...memberCommunities],
        ownerItems: [...ownerCommunities]
      });
    } else {
      await this.props.communityActionCreators.requestFetchCommunities();
      
    }
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.community.isLoading,
  isDirty: state.community.isDirty,
  isGuest: state.profile.isGuest,
  memberType: state.profile.memberType,
  favouriteCommunities: state.community.favouriteCommunities,
  memberCommunities: state.community.memberCommunities,
  memberTeams: state.community.memberTeams,
  ownerCommunities: state.community.ownerCommunities
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  requests: bindActionCreators(requestsActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CommunityGrid);
