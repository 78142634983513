/**
 * Commmunity grid view
 *
 * Displays a four instances of the community grid for
 * -favourites
 * -owner
 * -member
 * -suggested
 */

/**
 * React Redux
 */
import * as React from 'react';

/**
 * UI Fabric
 */

import { Dropdown, IDropdownOption, Icon } from '@fluentui/react';

/**
 * GovTeams dependencies
 */

interface ICommunityGridSorterProps {
  defaultKey: CommunityGridSortOrder;
  onChange: (key: CommunityGridSortOrder) => void;
}

interface ICommunityGridSorterState {
  selectedKeys: CommunityGridSortOrder;
}

export enum CommunityGridSortOrder {
  Default = 0,
  AlphabeticalAsc = 1,
  AlphabeticalDesc = 2,
  NewToOld = 3,
  OldToNew = 4
}

const sortOptions: IDropdownOption[] = [
  //{
  //    key: CommunityGridSortOrder.Default,
  //    text: 'Sort'
  //},
  {
    key: CommunityGridSortOrder.AlphabeticalAsc,
    text: 'A to Z'
  },
  {
    key: CommunityGridSortOrder.AlphabeticalDesc,
    text: 'Z to A'
  },
  {
    key: CommunityGridSortOrder.OldToNew,
    text: 'Oldest to Newest'
  },
  {
    key: CommunityGridSortOrder.NewToOld,
    text: 'Newest to Oldest'
  }
];

export class CommunityGridSorter extends React.Component<
  ICommunityGridSorterProps,
  ICommunityGridSorterState
> {
  constructor(props) {
    super(props);

    this.state = {
      selectedKeys: CommunityGridSortOrder.Default
    };
  }

  public render() {
    return (
      <Dropdown
        selectedKey={this.state.selectedKeys}
        placeholder='Sort'
        onRenderTitle={this.onRenderTitle}
        onRenderOption={this.onRenderOption}
        options={sortOptions}
        onChange={(
          event: React.FormEvent<HTMLDivElement>,
          option: IDropdownOption,
          index: number
        ) => {
          this.setState({
            selectedKeys: option.key as CommunityGridSortOrder
          });

          this.props.onChange(option.key as CommunityGridSortOrder);
        }}
        onRenderCaretDown={this.onRenderCaretDown}
        styles={{
          root: {
            minWidth: 180,
            textAlign: 'right'
          },
          title: {
            border: 'none',
            fontWeight: 600,
            color: '#2b88d8'
          },
          caretDownWrapper: {
            color: '#2b88d8'
          }
        }}
      />
    );
  }

  public componentDidMount() {
    this.setState({
      selectedKeys: this.props.defaultKey
    });
  }

  public componentDidUpdate(prevProps: ICommunityGridSorterProps) {
    if (prevProps.defaultKey !== this.props.defaultKey) {
      this.setState({
        selectedKeys: this.props.defaultKey
      });
    }
  }

  private onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden='true'
            title={option.data.icon}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  };

  private onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <div>
        {option.data && option.data.icon && (
          <Icon
            iconName={option.data.icon}
            aria-hidden='true'
            title={option.data.icon}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  };

  private onRenderCaretDown = (): JSX.Element => {
    switch (this.state.selectedKeys) {
      case CommunityGridSortOrder.AlphabeticalAsc:
        return <Icon iconName='SortUp' />;

      case CommunityGridSortOrder.AlphabeticalDesc:
        return <Icon iconName='SortDown' />;

      case CommunityGridSortOrder.OldToNew:
        return <Icon iconName='SortDown' />;

      case CommunityGridSortOrder.NewToOld:
        return <Icon iconName='SortUp' />;

      // case CommunityGridSortOrder.Default:

      default:
        return <Icon iconName='Sort' />;
    }
  };
}

export default CommunityGridSorter;
