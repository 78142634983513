/**
 * Dashboard wrapper
 *
 */

/**
 * React
 */
import * as React from 'react';
/**
 * Dashboard components
 */
import CommunityGrid from './CommunityGrid';
import CommunityList from './CommunityList';

/**
 * GovTeams dependencies
 */
export class DashboardCommunities extends React.Component<any, any> {
  public render() {
    return (
      <React.Fragment>
        {this.props.isListView ? <CommunityGrid /> : <CommunityList />}
      </React.Fragment>
    );
  }
}

export default DashboardCommunities;
