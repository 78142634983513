/**
 * Brings together dashboard components.
 * Controls screen and component views.
 * Handles responsiveness.
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators, MemberType } from '../../controllers/CommunityController';
import { memberPreferencesActionCreators } from '../../controllers/MemberPreferencesController';
import { quickLinksActionCreators } from './components/QuickLinks';
import { statisticsActionCreators } from './components/Statistics';
import { searchActionCreators } from './../Search';

/**
 * UI Fabric
 */
import { IconButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import {
  Shimmer,
  ShimmerElementType
} from '@fluentui/react/lib/Shimmer';

/**
 * Dashboard components
 */
import QuickLaunch from './components/QuickLaunch';
import DashboardProfile from './components/DashboardProfile';
import RecentDocuments from './components/RecentDocuments';
import InsiderFeed from './components/InsiderFeed/InsiderFeed';
import CommunityCreateButton from './components/Communities/CommunityCreateButton';
import CommunityPanel from '../../components/CommunityPanel/CommunityPanel';
import DashboardCommunities from './components/Communities/Communities';
import CommunityGridSorter from './components/Communities/CommunityGridSorter';

/**
 * Constants and utilities
 */
import { mediaQuery, screenSize } from '../../common/ResponsiveUtils';
import * as constants from '../../common/Constants';
import { isEqual } from 'lodash';
import { BRANDNAME } from '../../common/Tooltips';

import Carousel from './components/Carousel/Carousel';

interface IDashboardState {
  expandedView: boolean;
  isProfilePanelOpen: boolean;
  listView: boolean;
  screenWidth: number;
}

export class DashboardContentComponent extends React.Component<
  any,
  IDashboardState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      expandedView: false,
      isProfilePanelOpen: false,
      listView: false,
      screenWidth: window.innerWidth
    };
  }

  public render() {
    const { profile } = this.props;

    return (
      <React.Fragment>
        {mediaQuery.minWidth(this.state.screenWidth, screenSize.SCREEN_XL) && (
          <Shimmer
            isDataLoaded={profile}
            ariaLabel='Loading content'
            shimmerElements={[
              {
                type: ShimmerElementType.line,
                width: '100%',
                height: 46
              }
            ]}
          >
            <div className='dashboard-header'>
              <h2>
                Welcome,{' '}
                {profile.givenName &&
                profile.givenName !== '' &&
                profile.givenName !== null
                  ? profile.givenName
                  : profile.displayName}
                .
              </h2>
            <QuickLaunch showTeams={true} showSharepoint={true}/>
            </div>
          </Shimmer>
        )}

        <div className='dashboard'>
          <div className='dashboard-content'>
            {mediaQuery.minWidth(
              this.state.screenWidth,
              screenSize.SCREEN_XL
            ) ? (
              !this.state.expandedView && (
                <div className='dashboard-left'>
                  <DashboardProfile />
                  <InsiderFeed />
                </div>
              )
            ) : (
              <div className='dashboard-header'>
                <Persona
                  className='dashboard-profile-panelPersona'
                  text={profile.displayName}
                  imageUrl={profile.imageUrl}
                  size={
                    mediaQuery.minWidth(
                      this.state.screenWidth,
                      screenSize.SCREEN_MD
                    )
                      ? PersonaSize.size72
                      : PersonaSize.size48
                  }
                  onClick={this.onShowProfilePanel}
                />

                <QuickLaunch showTeams={true} showSharepoint={true} />
                {/* {mediaQuery.minWidth(
                  this.state.screenWidth,
                  screenSize.SCREEN_LG
                ) && <DashboardStatus />} */}
                <Panel
                  isOpen={this.state.isProfilePanelOpen}
                  onDismiss={this.onDismissProfilePanel}
                  type={PanelType.custom}
                  customWidth='400px'
                  isHiddenOnDismiss={true}
                >
                  <DashboardProfile />
                </Panel>
              </div>
            )}

            <div className='dashboard-right'>
              <Carousel />
              <div className='dashboard-communities'>
                <h1 className='screenReaderOnly'>Community dashboard</h1>
                <CommunityPanel />
                <div className='dashboard-communitiesHeader'>
                  <div>
                    <h2>Communities</h2>
                    <div>
                      Communities are a group of users collaborating together in {BRANDNAME}
                    </div>
                  </div>

                  <div className='dashboard-communitiesUtils'>
                    <CommunityGridSorter
                        defaultKey={this.props.preferences ? this.props.preferences.gridSortOrder : ''}
                      onChange={(key) => {
                        this.props.communityActionCreators.sortCommunities(key);
                        //comment out the next line to prevent auto saving and updating user preferences on change
                        this.props.memberPreferencesActionCreators.updateMemberPreferencesField(
                          'gridSortOrder',
                          key
                        );
                      }}
                    />

                    {this.state.listView ? (
                      <IconButton
                        text='Grid view'
                        iconProps={{ iconName: 'ViewAll' }}
                        onClick={this.onGridViewClick}
                      />
                    ) : (
                      <IconButton
                        text='List view'
                        iconProps={{ iconName: 'AllApps' }}
                        onClick={this.onListViewClick}
                      />
                    )}
                    {mediaQuery.minWidth(
                      this.state.screenWidth,
                      screenSize.SCREEN_XL
                    ) && (
                      <IconButton
                        text='Expanded view'
                        iconProps={
                          !this.state.expandedView
                            ? { iconName: 'Fullscreen' }
                            : { iconName: 'BackToWindow' }
                        }
                        onClick={this.onExpandedViewClick}
                      />
                    )}
                    {profile.memberType !== 'Sponsored' && profile.memberType !== MemberType.LAM && (
                      <CommunityCreateButton />
                    )}
                  </div>
                </div>
                <DashboardCommunities isListView={!this.state.listView} />
              </div>
              {!this.props.isGuest && profile.memberType !== 'Sponsored' && (
                <RecentDocuments />
              )}
              {mediaQuery.maxWidth(
                this.state.screenWidth,
                screenSize.SCREEN_XL - 1
              ) && (
                <React.Fragment>
                  <div className='govTeams-insider-feed-small-screen'>
                    <InsiderFeed />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

    public async componentDidMount() {
        const expandedView = localStorage.getItem('expandedView');
    const {
      dashboardIsListMode,
      gridSortOrder
    } = this.props.preferences;


        if(expandedView === null || expandedView === undefined) {
            localStorage.setItem('expandedView', 'false');
            this.setState({ expandedView: false });
        } else {
            if (expandedView === 'true') {
                this.setState({ expandedView: true });
            } else {
                this.setState({ expandedView: false });
            }
        }
    this.setState({ listView: dashboardIsListMode });
    
    await this.props.statisticsActions.requestFetchStatistics();
    await this.props.communityActionCreators.requestFetchNews();
    await this.props.communityActionCreators.requestFetchCommunities({
      gridSortOrder
    });
    await this.props.quickLinksActions.requestFetchQuickLinks();

    if (!this.props.isGuest && this.props.memberType !== 'Sponsored') {
      await this.props.search.requestFetchRecentDocuments(
        constants.RECENT_DOCUMENTS_MY
      );
    }

    if (this.props.ownerCommunities && this.props.memberCommunities) {
      this.setState({
        screenWidth: window.innerWidth
      });
    }

    window.addEventListener('resize', this.updateDimensions);
  }

  public componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.results, this.props.results)) {
      this.props.communityActionCreators.requestFetchCommunities();
    }

    if (!isEqual(prevProps.preferences, this.props.preferences)) {
      const {
        dashboardIsListMode,
        gridSortOrder
      } = this.props.preferences;

      this.setState({ listView: dashboardIsListMode });
      
      this.props.communityActionCreators.requestFetchCommunities({
        gridSortOrder
      });
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions, false);
  }

  private updateDimensions = (event) => {
    this.setState({ screenWidth: window.innerWidth });
  };

  private onDismissProfilePanel = () => {
    this.setState({
      isProfilePanelOpen: false
    });
  };

  private onShowProfilePanel = () => {
    this.setState({
      isProfilePanelOpen: !this.state.isProfilePanelOpen
    });
  };

  private onGridViewClick = () => {
    this.props.memberPreferencesActionCreators.updateMemberPreferencesField(
      'dashboardIsListMode',
      false
    );
    this.setState({ listView: false });
  };

  private onListViewClick = () => {
    this.props.memberPreferencesActionCreators.updateMemberPreferencesField(
      'dashboardIsListMode',
      true
    );
    this.setState({ listView: true });
  };

  private onExpandedViewClick = () => {
      if (!this.state.expandedView) {
          localStorage.setItem('expandedView', 'true');
      } else {
          localStorage.setItem('expandedView', 'false');
      }

      this.setState({ expandedView: !this.state.expandedView });

    //  const expandedView = !this.state.expandedView;
    //this.setState({ expandedView });
  };
}

const mapStateToProps = (state: any) => ({
  isGuest: state.profile.isGuest,
  favouriteCommunities: state.community.favouriteCommunities,
  memberCommunities: state.community.memberCommunities,
  ownerCommunities: state.community.ownerCommunities,
  profile: state.profile,
  recentDocuments: state.search.recentDocuments,
  results: state.inviteMembers.results,
  preferences: state.memberPreferences.preferences
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(
    communityActionCreators,
    dispatch
  ),
  memberPreferencesActionCreators: bindActionCreators(
    memberPreferencesActionCreators,
    dispatch
  ),
  quickLinksActions: bindActionCreators(quickLinksActionCreators, dispatch),
  statisticsActions: bindActionCreators(statisticsActionCreators, dispatch),
  search: bindActionCreators(searchActionCreators, dispatch)
});

export const DashboardContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContentComponent);

export default DashboardContent;
