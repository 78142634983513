import * as React from 'react';
import { connect } from 'react-redux';
import * as qs from 'qs';
import Activation from './Error.Activation';
import LoginFAM from './Error.LoginFAM';
import LoginGuest from './Error.LoginGuest';
import Generic from './Error.Generic';
import Revoked from './Error.InvitationRevoked';
import InvalidCode from './Error.InvalidCode';
import VerifyAccount from './Error.VerifyAccount';
import SovereignCloud from './Error.SovereignCloud';
import InviteExistingAzureUser from './Error.InviteExistingAzureUser';
import VerifyInvalidCode from './Error.VerifyInvalidCode';
import GroupMailbox from './Error.GroupMailbox';
import VerifyAccountBlocked from './Error.VerifyAccountBlocked';
import VerifyAccountBlockedByOrgAdmin from './Error.VerifyAccountBlockedByOrgAdmin';

class Error extends React.Component<any, any> {
  public render() {
    return <div className='govTeams-errorContent'>{this.getErrorScreen()}</div>;
  }

  private getCorrelationId() {
    const params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    if (params.CorrelationId) {
      return params.CorrelationId;
    } else {
      return 'unknown';
    }
  }
  private mailTo() {
    return `mailto: helpdesk@support.govteams.gov.au?subject = Assistance required for error with CorrelationId '${this.getCorrelationId()}`;
  }

  private getErrorScreen() {
    const params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const correlationId = this.getCorrelationId();
    const mailTo = this.mailTo();

    if (params.Error) {
      switch (params.Error) {
        case 'Activation':
          return (
            <Activation correlationId={correlationId} mailTo={mailTo} />
          );
        case 'LoginFAM':
          return (
            <LoginFAM
              correlationId={correlationId}
              mailTo={mailTo}
              url={this.getUrl()}
            />
          );
        case 'LoginGuest':
          return (
            <LoginGuest
              correlationId={correlationId}
              mailTo={mailTo}
              url={this.getUrl()}
            />
          );
        case 'Revoked':
          return (
            <Revoked correlationId={correlationId} mailTo={mailTo} />
          );
        case 'InvalidCode':
          return (
            <InvalidCode correlationId={correlationId} mailTo={mailTo} />
          );
        case 'VerifyAccount':
          return (
            <VerifyAccount
              correlationId={correlationId}
              mailTo={mailTo}
            />
          );
          case 'SovereignCloud':
              return (
                  <SovereignCloud
                      correlationId={correlationId}
                      mailTo={mailTo} />
              );
          case 'GroupMailbox':
              return (
                  <GroupMailbox
                      correlationId={correlationId}
                      mailTo={mailTo} />
              );
          case 'InviteExistingAzureUser':
              return (
                  <InviteExistingAzureUser
                      correlationId={correlationId}
                      mailTo={mailTo} />
              );
          case 'VerifyInvalidCodeError':
              return (
                  <VerifyInvalidCode correlationId={correlationId} mailTo={mailTo} />
              );
          case 'VerifyAccountBlocked':
              return (
                  <VerifyAccountBlocked
                      correlationId={correlationId}
                      mailTo={mailTo}
                  />
              );
          case 'VerifyAccountBlockedByOrgAdmin':
              return (
                  <VerifyAccountBlockedByOrgAdmin
                      correlationId={correlationId}
                      mailTo={mailTo}
                  />
              );
        default:
          return (
            <Generic correlationId={correlationId} mailTo={mailTo} />
          );
      }
    } else {
      return <Generic correlationId={correlationId} mailTo={mailTo} />;
    }
  }

  private getUrl() {
    return window.location.protocol + '//' + window.location.host + '/';
  }
}

export default connect()(Error);
