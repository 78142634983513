/**
 * Commmunity grid
 *
 * Displays a grid of community cards on the Dashboard
 */

/**
 * React
 */
import * as React from 'react';

/**
 * UI Fabric
 */
import { List } from '@fluentui/react/lib/List';
import {
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup
} from '@fluentui/react/lib/Shimmer';

/**
 * GovTeams dependencies
 */
import CommunityCard from './Card';
import { ICommunity } from '../../../../controllers/CommunityController';

import { isEmpty } from 'lodash';

const ROWS_PER_PAGE = 3;
const MAX_ROW_HEIGHT = 260;
const MAX_COL_WIDTH = 300;

interface ICommunityGridSectionProps {
  items: ICommunity[];
  isLoading: boolean;
}

export default class CommunityGridSection extends React.Component<
  ICommunityGridSectionProps,
  {}
> {
  private columnCount: number;
  private rowHeight: number;
  private shimmerElements: any[];

  constructor(props) {
    super(props);

    this.shimmerElements = this.getShimmerElements();

    // this.state = {
    //   currPage: 0,
    //   page: [],
    //   pageItems: [],
    //   items: [],
    // };
  }

  public render() {
    return (
      <React.Fragment>
        <Shimmer
          isDataLoaded={!this.props.isLoading}
          width={'100%'}
          customElementsGroup={
            <ShimmerElementsGroup shimmerElements={this.shimmerElements} />
          }
        >
          <List
            // ref={ref => (this.list = ref)}
            className='ms-ListGrid'
            items={!isEmpty(this.props.items) ? [...this.props.items] : []}
            getItemCountForPage={this.getItemCountForPage}
            getPageHeight={this.getPageHeight}
            renderedWindowsAhead={4}
            onRenderCell={this.onRenderCell}
          />
          {
            // Pagination disabled
            /* {this.props.items && this.props.items.length > 0 && (
          <div className="govTeams-searchPager">
            {this.state.currPage > 0 && this.state.pageItems.length > 1 && (
              <ActionButton
                className="govTeams-searchPager--prev"
                onClick={this.onPrevious}
              >
                <Icon iconName="ChevronLeft" />
                Previous
              </ActionButton>
            )}
            {this.state.pageItems.length - 1 > this.state.currPage && (
              <ActionButton
                className="govTeams-searchPager--next"
                onClick={this.onNext}
              >
                Next
                <Icon iconName="ChevronRight" />
              </ActionButton>
            )}
          </div>
        )} */
          }
        </Shimmer>
      </React.Fragment>
    );
  }

  // public componentDidMount() {
  //   this.setState({
  //     displayItems: !isEmpty(this.props.items) ? [...this.props.items] : [],
  //   });
  // }

  // public componentDidUpdate(prevProps, prevState) {
  //   console.dir(this.props);
  //   if (!isEqual(this.props.items, prevProps.items)) {
  //     console.log('update');
  //     this.setState({
  //       displayItems: !isEmpty(this.props.items) ? [...this.props.items] : [],
  //     });
  //   }
  // }
  /**
   * Pagination disabled
   */
  // public componentDidMount() {
  //   if (this.props.items) {
  //     const pageItems = this.setPages(this.props.items);

  //     this.setState({
  //       currPage: 0,
  //       page: pageItems[0],
  //       pageItems
  //     });
  //   }
  // }

  // public componentDidUpdate(prevProps) {
  //   if (this.props.items !== prevProps.items) {
  //     const pageItems = this.setPages(this.props.items);

  //     this.setState({
  //       currPage: 0,
  //       page: pageItems[0],
  //       pageItems
  //     });
  //   }
  // }

  private getShimmerElements() {
    let columnCount = 5;
    const sectionWidth = window.innerWidth * 0.7;
    if (sectionWidth >= 1024) {
      columnCount = Math.ceil(sectionWidth / MAX_COL_WIDTH);
    } else if (sectionWidth < 1024 && sectionWidth >= 768) {
      columnCount = 3;
    } else if (sectionWidth < 768 && sectionWidth >= 480) {
      columnCount = 2;
    } else {
      columnCount = 1;
    }

    const shimmerElements: any[] = [];

    for (let count = 0; count < columnCount; count++) {
      shimmerElements.push(
        {
          type: ShimmerElementType.line,
          width: `${100 / columnCount}%`,
          height: 230
        },
        {
          type: ShimmerElementType.gap,
          width: 30,
          height: 230
        }
      );
    }

    return shimmerElements;
  }

  private getPageWidth = (): number => {
    if ( window.innerWidth < 1024 )
      return window.innerWidth - 150;
    else
      return window.innerWidth - 532;
  };

  private getItemCountForPage = (itemIndex, surfaceRect) => {
    if (itemIndex === 0) {
      const screenWidth = surfaceRect.width > 1 ? surfaceRect.width:this.getPageWidth();
      /**
       * Responsive layout for cards
       */
      if (screenWidth >= 1024) {
        this.columnCount = Math.ceil(screenWidth / MAX_COL_WIDTH);
      } else if (screenWidth < 1024 && screenWidth >= 768) {
        this.columnCount = 3;
      } else if (screenWidth < 768 && screenWidth >= 480) {
        this.columnCount = 2;
      } else {
        this.columnCount = 1;
      }

      // this.columnWidth = Math.floor(surfaceRect.width / this.columnCount);
      this.rowHeight = MAX_ROW_HEIGHT;
    }

    return this.columnCount * ROWS_PER_PAGE;
  };

  private getPageHeight = (itemIndex, surfaceRect) => {
    return this.rowHeight * ROWS_PER_PAGE;
  };

  // private resolveList = list => {
  //   this.list = list;
  // };

  private onRenderCell = (item, index) => {
    return (
      item.communityId && (
        <div
          className='ms-ListGrid-tile'
          data-is-focusable={true}
          style={{ width: 100 / this.columnCount + '%' }}
        >
          <div className='ms-ListGrid-sizer'>
            <div className='msListGrid-padder'>
              <CommunityCard {...item} />
            </div>
          </div>
        </div>
      )
    );
  };

  /**
   * Pagination disabled
   */
  // private setPages(items) {
  //   const pageItems:any = [];
  //   let count = 0;
  //   let page = 0;

  //   items.forEach(item => {
  //     if (!pageItems[page]) {
  //       pageItems[page] = [];
  //     }

  //     if (count < 12) {
  //       pageItems[page].push(item);
  //       count++;
  //     }

  //     if (count === 12) {
  //       page++;
  //       count = 0;
  //     }
  //   });

  //   return pageItems;
  // }

  // private onNext = event => {
  //   const nextPage = this.state.currPage + 1;

  //   // this.list.scrollToIndex(0);

  //   this.setState({
  //     currPage: nextPage,
  //     page: this.state.pageItems[nextPage]
  //   });
  // };

  // private onPrevious = event => {
  //   const prevPage = this.state.currPage - 1;

  //   // this.list.scrollToIndex(0);

  //   this.setState({
  //     currPage: prevPage,
  //     page: this.state.pageItems[prevPage]
  //   });
  // };
}
