import {push} from 'react-router-redux';
import {notificationActionCreators} from '../../components/Notification';
import {progressActionCreators} from '../../components/Progress';
import * as constants from '../../common/Constants';
import {sortItems, sortItemsByIntProperty} from '../../common/Utils';

import {CommunityRequestOptions, CommunityType, ICommunity} from './Community.types';
import actions from './Community.actions';
import {CommunityGridSortOrder} from '../../screens/Dashboard/components/Communities/CommunityGridSorter';
import {cloneDeep} from 'lodash';
import {BRANDNAME} from '../../common/Tooltips';
import {getAuthFromState} from '../../auth/authHandler';
import {
    httpServiceSecureDelete,
    httpServiceSecureGet,
    httpServiceSecureGetRaw,
    httpServiceSecurePost, httpServiceSecurePut,
} from '../../common/Http';
import {saveCommunityManageEvent} from '../../common/EventTracking';

const FileSaver = require('file-saver');

const MESSAGE_COMMUNITY_SAVE =
    "Success! We've received your request to update your community details. It might take about an hour for your changes to be updated across all Microsoft systems.";
const MESSAGE_COMMUNITY_SAVE_ERROR =
    "Updating community '%DISPLAYNAME%' has failed' %ERROR%";
const MESSAGE_COMMUNITY_CLOSE =
    `Your community '%DISPLAYNAME%' has been deleted. The community will no longer be available in ${BRANDNAME} and Microsoft Office 365.`;
const MESSAGE_COMMUNITY_CLOSE_ERROR =
    "Closing community '%DISPLAYNAME%' has failed' Error: %ERROR%";

export const communityActionCreators = {
    setFormIsDirty: () => (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_FORM_DIRTY
        });
    },

    acceptedTnCCommunity: () => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_TNC_ACCEPTED
        });
    },

    startEditManage: (community) => async (dispatch, getState) => {
        if (community === null) {
            // saveInteractionWithCommunityEvent('open_create',  'create');
            dispatch({
                type: actions.COMMUNITY_EDIT_START_CREATE
            });
            dispatch(push(constants.SCREEN_COMMUNITY));
        } else {
            // saveInteractionWithCommunityEvent('open_detail', community.displayName);

            dispatch({
                type: actions.COMMUNITY_MANAGE_START,
                communityId: community.communityId,
                initialValues: community
            });
            dispatch(
                push(`${constants.SCREEN_COMMUNITY}/${community.communityAzureId}`)
            );
        }
    },

    endEditManage: () => async (dispatch, getState) => {
        dispatch({ type: actions.COMMUNITY_MANAGE_END });
        dispatch(push(constants.SCREEN_DASHBOARD));
    },

    startEditCommunity: (community: ICommunity) => async (dispatch, getState) => {
        if (community == null) {
            saveCommunityManageEvent('create', 'create community');

            dispatch({
                type: actions.COMMUNITY_EDIT_START_CREATE
            });
            dispatch(push(constants.SCREEN_COMMUNITY));
        } else {
            saveCommunityManageEvent('edit', 'edit community');
            dispatch({
                type: actions.COMMUNITY_EDIT_START,
                communityId: community.communityId,
                initialValues: community
            });
        }
    },

    endEditCommunity: (communityId) => async (dispatch, getState) => {
        if (communityId) {
            if (getState().community.isDirty) {
                dispatch(communityActionCreators.requestFetchCommunity(communityId));
            }
            dispatch({
                type: actions.COMMUNITY_EDIT_END
            });
        } else {
            if (getState().community.isDirty) {
                dispatch(communityActionCreators.requestFetchCommunities());
            }

            dispatch(push(constants.SCREEN_DASHBOARD));
        }
    },

    exportCommunityMemberCsv: (communityId) => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_MEMBERS_EXPORTCSV_REQUEST
        });
        saveCommunityManageEvent('export_members', 'export members');

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            await httpServiceSecureGetRaw(`/members/${communityId}/members.csv`, gtToken, { Accept: 'text/csv' })
                .then((res) => res.blob())
                .then((blob) => FileSaver.saveAs(blob, 'members.csv'));


            dispatch({
                type: actions.COMMUNITY_MEMBERS_EXPORTCSV_RESPONSE
            });
        } catch (error) {
            dispatch({
                type: actions.COMMUNITY_MEMBERS_EXPORTCSV_RESPONSE,
                error
            });
        }
    },

    exportCommunityInvitesCsv: (communityId) => async (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_INVITES_EXPORTCSV_REQUEST
        });
        saveCommunityManageEvent('export_invites', 'export invites');


        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            await httpServiceSecureGetRaw(`/invites/${communityId}/invitations.csv`, gtToken, { Accept: 'text/csv' })
                    .then((res) => res.blob())
                    .then((blob) => FileSaver.saveAs(blob, 'invitations.csv'));


            dispatch({
                type: actions.COMMUNITY_INVITES_EXPORTCSV_RESPONSE
            });
        } catch (error) {
            dispatch({
                type: actions.COMMUNITY_INVITES_EXPORTCSV_RESPONSE,
                error
            });
        }
    },

    /**
     * Does not do any action as there are no dispatches
     *
     */
    updateOwnerCommunities: (communityId, community) => async (
        dispatch,
        getState
    ) => {
        const ownerCommunities = getState().community.ownerCommunities;

        if (ownerCommunities.length !== undefined) {
            ownerCommunities.forEach((ownerCommunity) => {
                if (ownerCommunity.communityId === communityId) {
                    ownerCommunity.displayName = community.displayName;
                    ownerCommunity.description = community.description;
                    ownerCommunity.tags = community.tags;
                    ownerCommunity.communityType = community.communityType;
                    return;
                }
            });
        }
    },

    /**
     * Favourite and unfavourite community
     */
    toggleFavourite: (community: ICommunity) => async (dispatch, getState) => {
        const ownerCommunities: ICommunity[] = [];
        const memberCommunities: ICommunity[] = [];
        const favouriteCommunities: ICommunity[] = [];
        let addFavourite: boolean = false;

        const currentOwnerCommunities: ICommunity[] = getState().community
            .ownerCommunities;

        if (currentOwnerCommunities) {
            currentOwnerCommunities.forEach((ownerCommunity: any) => {
                if (ownerCommunity.communityId === community.communityId) {
                    ownerCommunity.isFavourite = !ownerCommunity.isFavourite;
                    addFavourite = ownerCommunity.isFavourite;
                }

                if (ownerCommunity.isFavourite === true) {
                    favouriteCommunities.push(ownerCommunity);
                }

                ownerCommunities.push(ownerCommunity);
            });
        }

        const currentMemberCommunities = getState().community.memberCommunities;

        if (currentMemberCommunities) {
            currentMemberCommunities.forEach((memberCommunity: any) => {
                if (memberCommunity.communityId === community.communityId) {
                    memberCommunity.isFavourite = !memberCommunity.isFavourite;
                    addFavourite = memberCommunity.isFavourite;
                }

                if (memberCommunity.isFavourite === true) {
                    favouriteCommunities.push(memberCommunity);
                }

                memberCommunities.push(memberCommunity);
            });
        }

        dispatch({
            type: actions.COMMUNITIES_FETCH_RESPONSE,
            favouriteCommunities,
            ownerCommunities,
            memberCommunities
        });
        const { gtToken } = await getAuthFromState(getState, dispatch);

        if (addFavourite) {
            try {
                await httpServiceSecurePost(`/favourites/${community.communityId}`, gtToken);
                dispatch(communityActionCreators.requestFetchCommunities());
            } catch (error) {
                dispatch(
                    notificationActionCreators.startShowNotification(
                        'Failed to add the favourite'
                    )
                );
                dispatch(communityActionCreators.requestFetchCommunities());
            }
        } else {
            try {
                await httpServiceSecureDelete(`/favourites/${community.communityId}`, gtToken);
            } catch (error) {
                dispatch(
                    notificationActionCreators.startShowNotification(
                        'Failed to remove the favourite'
                    )
                );
                dispatch(communityActionCreators.requestFetchCommunities());
            }
        }

        /**
         * Update favourite when toggled from panel
         */
        const favouriteCommunity: ICommunity = JSON.parse(
            JSON.stringify(community)
        );

        favouriteCommunity.isFavourite = addFavourite;

        dispatch({
            type: actions.COMMUNITY_MANAGE_UPDATE,
            initialValues: favouriteCommunity
        });
    },

    /**
     * Fetch community using Azure GUID
     */
    requestFetchCommunity: (communityAzureId, loadTags: boolean = false) => async (
        dispatch,
        getState
    ) => {
        if (getState().community.isLoading) {
            return;
        }

        dispatch({ type: actions.COMMUNITY_FETCH_REQUEST });
        dispatch(progressActionCreators.startShowProgress('Fetching community'));

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const initialValues: ICommunity = await httpServiceSecureGet(`/communities/${communityAzureId}`, gtToken);

            /**
             * Subsequent request to use internal ID
             */
            if (loadTags) {
                initialValues.tags = await httpServiceSecureGet(`/communities/${initialValues.communityId}/tags`, gtToken);
            }

            dispatch({
                type: actions.COMMUNITY_FETCH_RESPONSE,
                communityId: initialValues.communityId,
                initialValues
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.ERROR_COMMUNITY_FETCH
                )
            );
            dispatch({ type: actions.COMMUNITY_FETCH_RESPONSE, error });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    requestSaveCommunity: (communityId) => async (dispatch, getState) => {
        if (getState().community.isLoading) {
            return;
        }


        dispatch({ type: actions.COMMUNITY_SAVE_REQUEST });
        dispatch(progressActionCreators.startShowProgress('Saving community'));

        const communityForm = getState().form.CommunityForm.values;
        const { gtToken } = await getAuthFromState(getState, dispatch);

        if (communityId) {
            try {
                await httpServiceSecurePut(`/communities/${communityId}`, gtToken, communityForm);
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: true,
                    error: ''
                });
                saveCommunityManageEvent('updated', communityForm?.displayName);

                /**
                 * To do: check reinitialisation after saving
                 *
                 */
                const existingValues: ICommunity = getState().community.initialValues;
                const initialValues: ICommunity = {
                    communityAzureId: existingValues.communityAzureId,
                    communityId: existingValues.communityId,
                    communityType: CommunityType[communityForm.typeId],
                    iconPreferenceId: communityForm.iconPreferenceId,
                    isCreated: existingValues.isCreated,
                    isFavourite: existingValues.isFavourite,
                    isMember: existingValues.isMember,
                    isOwner: existingValues.isOwner,
                    communityMembershipId: existingValues.communityMembershipId,
                    displayName: communityForm.displayName,
                    url: existingValues.url,
                    totalMembers: existingValues.totalMembers,
                    pendingRequests: existingValues.pendingRequests,
                    description: communityForm.description,           
                    extendedDescription: communityForm.extendedDescription,
                    includeExtendedDescToWelcomeEmail: existingValues.includeExtendedDescToWelcomeEmail,
                    sharePointUrl: existingValues.sharePointUrl,
                    sponsored: existingValues.sponsored,
                    tags: []
                };

                communityForm.tags.forEach((tag: any) => {
                    initialValues.tags.push('#' + tag.name);
                });

                dispatch({
                    type: actions.COMMUNITY_MANAGE_START,
                    communityId,
                    initialValues
                });
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: false
                });
                dispatch(communityActionCreators.endEditCommunity(communityId));
                dispatch(
                    notificationActionCreators.startShowNotification(
                        MESSAGE_COMMUNITY_SAVE
                    )
                );
            } catch (error) {
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: false,
                    isDirty: true,
                    error: error.toString(),
                });
                dispatch(
                    notificationActionCreators.startShowNotification(
                        MESSAGE_COMMUNITY_SAVE_ERROR.replace(
                            '%DISPLAYNAME%',
                            getState().community.initialValues.displayName
                        ).replace('%ERROR%', error.toString())
                    )
                );
            }

            dispatch(progressActionCreators.endShowProgress());
        } else {
            dispatch(
                progressActionCreators.startShowProgress('Creating new community...')
            );

            try {
                await httpServiceSecurePost('/communities', gtToken, communityForm);

                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: true,
                    isDirty: true,
                    error: ''
                });
                saveCommunityManageEvent('created', communityForm?.displayName);
                dispatch(progressActionCreators.endShowProgress());
            } catch (error) {
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isSubmitted: false,
                    isDirty: true,
                    error: error.toString(),
                });
                dispatch(progressActionCreators.endShowProgress());
            }
        }
    },

    requestCloseCommunity: (communityId) => async (dispatch, getState) => {
        if (getState().community.isLoading) {
            return;
        }
        dispatch({ type: actions.COMMUNITY_CLOSE_REQUEST });

        if (communityId) {
            dispatch(
                progressActionCreators.startShowProgress('Closing community...')
            );
            try {
                const { gtToken } = await getAuthFromState(getState, dispatch);
                await httpServiceSecureGet(`/communities/${communityId}/close`, gtToken);

                dispatch(progressActionCreators.endShowProgress());
                dispatch({
                    type: actions.COMMUNITY_CLOSE_RESPONSE,
                    isDirty: true
                });
                saveCommunityManageEvent('closed', communityId);

                dispatch(
                    notificationActionCreators.startShowNotification(
                        MESSAGE_COMMUNITY_CLOSE.replace(
                            '%DISPLAYNAME%',
                            getState().community.initialValues.displayName
                        )
                    )
                );
                dispatch(push(constants.SCREEN_DASHBOARD));
                dispatch(communityActionCreators.requestFetchCommunities());
            } catch (response) {
                const json = await response.json();
                dispatch({
                    type: actions.COMMUNITY_SAVE_RESPONSE,
                    isDirty: false,
                    error: json
                });
                dispatch(
                    notificationActionCreators.startShowNotification(
                        MESSAGE_COMMUNITY_CLOSE_ERROR.replace(
                            '%DISPLAYNAME%',
                            getState().community.initialValues.displayName
                        ).replace('%ERROR%', json)
                    )
                );
                dispatch(progressActionCreators.endShowProgress());
            }
        }
    },

    requestFetchNews: () => async (dispatch, getState) => {
        if (getState().community.isLoading) {
            return;
        }

        dispatch({ type: actions.COMMUNITIES_FETCH_REQUEST });

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const news: any = await httpServiceSecureGet(`/communities/${getState().profile.id}/news`, gtToken);

            dispatch({
                type: actions.NEWS_FETCH_RESPONSE,
                communityId: news,
                news,
            });
        } catch (error) {
            dispatch(
            );
            dispatch({
                type: actions.NEWS_FETCH_RESPONSE,
                error
            });
        }
    },

    requestFetchCommunities: (options?: CommunityRequestOptions) => async (
        dispatch,
        getState
    ) => {
        if (getState().community.isLoading) {
            return;
        }

        dispatch({ type: actions.COMMUNITIES_FETCH_REQUEST });

        const ownerCommunities: any[] = [];
        const memberCommunities: any[] = [];
        const favouriteCommunities: any[] = [];

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const communities: any = await httpServiceSecureGet('/communities/List', gtToken);

            if (communities && communities.length > 0) {
                communities.forEach((community) => {
                    if (community.isFavourite) {
                        favouriteCommunities.push(community);
                    }
                    if (community.isOwner) {
                        ownerCommunities.push(community);
                    }
                    if (community.isMember && !community.isOwner) {
                        memberCommunities.push(community);
                    }
                });

                if (options) {

                    switch (options.gridSortOrder) {
                        case CommunityGridSortOrder.AlphabeticalDesc:
                            sortItems(ownerCommunities, 'displayName', true);
                            sortItems(favouriteCommunities, 'displayName', true);
                            sortItems(memberCommunities, 'displayName', true);
                            break;

                        case CommunityGridSortOrder.NewToOld:
                            sortItemsByIntProperty(ownerCommunities, 'communityMembershipId', true);
                            sortItemsByIntProperty(favouriteCommunities, 'communityMembershipId', true);
                            sortItemsByIntProperty(memberCommunities, 'communityMembershipId', true);
                            break;

                        case CommunityGridSortOrder.OldToNew:
                            sortItemsByIntProperty(ownerCommunities, 'communityMembershipId', false);
                            sortItemsByIntProperty(favouriteCommunities, 'communityMembershipId', false);
                            sortItemsByIntProperty(memberCommunities, 'communityMembershipId', false);
                            break;

                        case CommunityGridSortOrder.AlphabeticalAsc:
                        default:
                            sortItems(ownerCommunities, 'displayName', false);
                            sortItems(favouriteCommunities, 'displayName', false);
                            sortItems(memberCommunities, 'displayName', false);
                            break;
                    }
                }
                else {
                    sortItems(ownerCommunities, 'displayName', false);
                    sortItems(favouriteCommunities, 'displayName', false);
                    sortItems(memberCommunities, 'displayName', false);
                }


            }
            

            dispatch({
                type: actions.COMMUNITIES_FETCH_RESPONSE,
                favouriteCommunities,
                ownerCommunities,
                memberCommunities
            });

            // dispatch(communityActionCreators.requestFetchSuggestedCommunity());
        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.ERROR_OWNERCOMMUNITIES_FETCH
                )
            );
            dispatch({
                type: actions.COMMUNITIES_FETCH_RESPONSE,
                error
            });
        }
    },

    sortCommunities: (sortKey: CommunityGridSortOrder) => (
        dispatch,
        getState
    ) => {
        const ownerCommunities = getState().community.ownerCommunities;
        const favouriteCommunities = cloneDeep(
            getState().community.favouriteCommunities
        );
        const memberCommunities = getState().community.memberCommunities;
        const preferences = getState().memberPreferences.preferences;
        const defaultKey = preferences.gridSortOrder;

        switch (sortKey) {
            case CommunityGridSortOrder.AlphabeticalAsc:
                sortItems(ownerCommunities, 'displayName', false);
                sortItems(favouriteCommunities, 'displayName', false);
                sortItems(memberCommunities, 'displayName', false);
                break;
            case CommunityGridSortOrder.AlphabeticalDesc:
                sortItems(ownerCommunities, 'displayName', true);
                sortItems(favouriteCommunities, 'displayName', true);
                sortItems(memberCommunities, 'displayName', true);
                break;
            case CommunityGridSortOrder.OldToNew:
                sortItemsByIntProperty(ownerCommunities, 'communityMembershipId', false);
                sortItemsByIntProperty(favouriteCommunities, 'communityMembershipId', false);
                sortItemsByIntProperty(memberCommunities, 'communityMembershipId', false);
                break;
            case CommunityGridSortOrder.NewToOld:
                sortItemsByIntProperty(ownerCommunities, 'communityMembershipId', true);
                sortItemsByIntProperty(favouriteCommunities, 'communityMembershipId', true);
                sortItemsByIntProperty(memberCommunities, 'communityMembershipId', true);
                break;
            case defaultKey:
            case CommunityGridSortOrder.Default:
            default:
                if (defaultKey) {
                    switch (defaultKey) {
                        case CommunityGridSortOrder.AlphabeticalAsc:
                            sortItems(ownerCommunities, 'displayName', false);
                            sortItems(favouriteCommunities, 'displayName', false);
                            sortItems(memberCommunities, 'displayName', false);
                            break;
                        case CommunityGridSortOrder.AlphabeticalDesc:
                            sortItems(ownerCommunities, 'displayName', true);
                            sortItems(favouriteCommunities, 'displayName', true);
                            sortItems(memberCommunities, 'displayName', true);
                            break;
                        default:
                            sortItems(ownerCommunities, 'displayName', false);
                            sortItems(favouriteCommunities, 'displayName', false);
                            sortItems(memberCommunities, 'displayName', false);
                            break;
                    }
                } else {
                    sortItems(ownerCommunities, 'displayName', false);
                    sortItems(favouriteCommunities, 'displayName', false);
                    sortItems(memberCommunities, 'displayName', false);
                }

                break;
        }

        dispatch({
            type: actions.COMMUNITIES_SORT,
            favouriteCommunities,
            ownerCommunities,
            memberCommunities
        });
    },

    updateMemberCommunities: (communityId) => async (dispatch, getState) => {
        let initialValues;

        if (window.location.pathname === '/Search') {
            const searchCommunities = getState().search.searchCommunities
                ? getState().search.searchCommunities.slice()
                : getState().search.searchCommunities;

            if (searchCommunities) {
                initialValues = searchCommunities.filter(
                    (sc) => sc.communityId === communityId
                )[0];

                initialValues.isMember = true;
                initialValues.totalMembers++;

                dispatch({
                    type: actions.COMMUNITY_MANAGE_UPDATE,
                    initialValues: JSON.parse(JSON.stringify(initialValues))
                });
            }

            dispatch({
                type: actions.COMMUNITY_UPDATE
            });

            return;
        }

        let suggestedCommunities = getState().community.suggestedCommunities
            ? getState().community.suggestedCommunities.slice()
            : getState().community.suggestedCommunities;

        const suggestedCommunity = suggestedCommunities.filter(
            (community) => community.communityId === communityId
        )[0];

        if (suggestedCommunity) {
            suggestedCommunity.isMember = true;
            suggestedCommunity.totalMembers++;
            suggestedCommunities = suggestedCommunities.filter(
                (community) => community.communityId !== communityId
            );
        }

        const memberCommunities = getState().community.memberCommunities
            ? getState().community.memberCommunities.slice()
            : getState().community.memberCommunities;

        memberCommunities.push(suggestedCommunity);

        if (suggestedCommunity) {
            initialValues = JSON.parse(JSON.stringify(suggestedCommunity));
        }

        dispatch({
            type: actions.MEMBERCOMMUNITY_UPDATE,
            memberCommunities,
            suggestedCommunities
        });
        dispatch({
            type: actions.COMMUNITY_MANAGE_UPDATE,
            initialValues
        });
    },

    updateSuggestedCommunities: (communityId) => async (dispatch, getState) => {
        let initialValues;

        if (window.location.pathname === '/Search') {
            const searchCommunities = getState().search.searchCommunities
                ? getState().search.searchCommunities.slice()
                : getState().search.searchCommunities;

            if (searchCommunities) {
                initialValues = searchCommunities.filter(
                    (community) => community.communityId === communityId
                )[0];

                if (initialValues !== undefined) {
                    initialValues.isMember = false;
                    initialValues.totalMembers--;

                    /**
                     * Parse JSON again to trigger update
                     */
                    dispatch({
                        type: actions.COMMUNITY_MANAGE_UPDATE,
                        initialValues: JSON.parse(JSON.stringify(initialValues))
                    });
                }
            }

            dispatch({
                type: actions.COMMUNITY_UPDATE
            });

            return;
        }

        let memberCommunities = getState().community.memberCommunities
            ? getState().community.memberCommunities.slice()
            : getState().community.memberCommunities;

        const memberCommunity = memberCommunities.filter(
            (community) => community.communityId === communityId
        )[0];

        const suggestedCommunities = getState().community.suggestedCommunities
            ? getState().community.suggestedCommunities.slice()
            : getState().community.suggestedCommunities;

        if (memberCommunity !== undefined) {
            memberCommunity.isMember = false;
            memberCommunity.totalMembers--;
            suggestedCommunities.push(memberCommunity);

            /**
             * Force new value to trigger update
             */
            initialValues = JSON.parse(JSON.stringify(memberCommunity));

            dispatch({
                type: actions.COMMUNITY_MANAGE_UPDATE,
                initialValues
            });
        }

        memberCommunities = memberCommunities.filter(
            (community) => community.communityId !== communityId
        );

        dispatch({
            type: actions.MEMBERCOMMUNITY_UPDATE,
            suggestedCommunities,
            memberCommunities
        });
    },

    requestSimilarCommunities: () => async (dispatch, getState) => {
        if (getState().profile.isGuest) {
            return;
        }

        if (getState().community.isLoading) {
            return;
        }

        const communityForm = getState().form.CommunityForm.values;

        if (!communityForm || !communityForm.displayName) {
            return;
        }

        const displayName = communityForm.displayName
            ? communityForm.displayName
            : '';
        let tags = '';
        const similarCommunities: any[] = [];

        if (communityForm.tags && communityForm.tags.length > 0) {
            communityForm.tags.forEach((tag) => {
                tags += tag.name + ';';
            });
        }

        dispatch({ type: actions.SIMILARCOMMUNITIES_FETCH_REQUEST });

        try {
            const { gtToken } = await getAuthFromState(getState, dispatch);
            const communities = await httpServiceSecureGet(`/communities/Similar?filter=${displayName}&projects=${tags}`, gtToken);

            if (communities && communities.length > 0) {
                for (let i = 0; i < communities.length && i < 3; i++) {
                    const communityData: any = communities[i];
                    similarCommunities.push(communityData);
                }
            }

            dispatch({
                type: actions.SIMILARCOMMUNITIES_FETCH_RESPONSE,
                similarCommunities
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.ERROR_SUGGESTEDCOMMUNITIES_FETCH
                )
            );
            dispatch({
                type: actions.SIMILARCOMMUNITIES_FETCH_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    requestFetchSuggestedCommunity: () => async (dispatch, getState) => {
        if (getState().profile.isGuest) {
            return;
        }

        if (getState().community.isSuggestedLoading) {
            return;
        }

        dispatch({ type: actions.SUGGESTEDCOMMUNITY_FETCH_REQUEST });
        const suggestedCommunities: any[] = [];
        /*
             Suggested Communities were disabled on 11/7/2019.  Uncomment these sections to enable suggested communities again.
             */
        //const skills = getState().profile.skills ? getState().profile.skills : '';
        //const interests = getState().profile.interests
        //  ? getState().profile.interests
        //  : '';
        //const projects = getState().profile.projects
        //  ? getState().profile.projects
        //  : '';

        //const apiToken: string = await adalGetToken(
        //  authContext,
        //  adalConfig.endpoints.api.guid
        //);
        try {
            //const response = await fetch(
            //  API_URL() +
            //    `/communities/Suggested?skills=${skills}&interests=${interests}&projects=${projects}`,
            //  {
            //    headers: {
            //      Authorization: 'Bearer ' + apiToken,
            //      'Content-Type': 'application/json'
            //    }
            //  }
            //);
            //const communities = await response.json();

            //if (communities && communities.length > 1) {
            //  communities.forEach(community => {
            //    if (!community.isMember && !community.isOwner) {
            //      suggestedCommunities.push(community);
            //    }
            //  });
            //}

            dispatch({
                type: actions.SUGGESTEDCOMMUNITY_FETCH_RESPONSE,
                suggestedCommunities
            });
            dispatch(progressActionCreators.endShowProgress());
        } catch (error) {
            dispatch(
                notificationActionCreators.startShowNotification(
                    constants.ERROR_SUGGESTEDCOMMUNITIES_FETCH
                )
            );
            dispatch({
                type: actions.SUGGESTEDCOMMUNITY_FETCH_RESPONSE,
                error
            });
            dispatch(progressActionCreators.endShowProgress());
        }
    },

    viewCommunityPanel: (community) => (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_PANEL_VIEW_START,
            communityId: community.communityId,
            initialValues: community
        });
    },

    closeCommunityPanel: () => (dispatch, getState) => {
        dispatch({
            type: actions.COMMUNITY_PANEL_VIEW_END
        });
    }
};

//

export default communityActionCreators;
