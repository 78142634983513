/**
 * Community edit modal
 * - Used on community management page
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { communityActionCreators } from './../../controllers/CommunityController';

/**
 * Fabric UI
 */
import { Dialog } from '@fluentui/react/lib/Dialog';

/**
 * GovTEAMS component
 */
import CommunityFormComponent from '@govteams/library/components/Community/CommunityFormComponent/';
import {BRANDNAME} from '../../common/Tooltips';
import {isProtected} from '../../common/Utils';
import {GET_API_URL} from '../../auth/authConfig';
import * as tooltips from '../../common/Tooltips';

export class CommunityEditModalComponent extends React.Component<any, any> {
  public render() {
    const { profile, communityId, auth, communityActionCreators, initialValues } = this.props;

    return (
      <Dialog
        hidden={!this.props.isEditModalOpen}
        onDismiss={this.onDismiss}
        minWidth={800}
        modalProps={{
          // className: 'govTeams-dialog',
          styles:{ main: {
            maxHeight: '90vh'
          }}
        }}
      >
        <h2>Community detail</h2>
        <CommunityFormComponent
          isEditForm={true}

          brandName={BRANDNAME}
          profile={profile}
          initialValues={initialValues}
          communityId={communityId}
          isProtected={isProtected()}

          isFormDirty={false}
          setFormIsDirty={null}
          findSimilarCommunities={null}
          similarCommunities={null}
          onSimilarCommunityClick={null}

          requestFetchCommunity={communityActionCreators.requestFetchCommunity}
          requestSaveCommunity={communityActionCreators.requestSaveCommunity}
          endEditCommunity={communityActionCreators.endEditCommunity}

          tagPickerResolveUrl={`${GET_API_URL()}/tags/`}
          authToken={auth}

          tooltipSponsoredBadge={tooltips.SPONSORED_BADGE}
        />
      </Dialog>
    );
  }

  private onDismiss = (): void => {
    this.props.communityActionCreators.endEditCommunity(this.props.communityId);
  };
}

const mapStateToProps = (state: any) => ({
  isEditModalOpen: state.community.isEditModalOpen,
  communityId: state.community.communityId,
  communityAzureId: state.community.communityAzureId,

  createError: state.community.error,
  initialValues: state.community.initialValues,
  communityFormState: state.form.CommunityForm,
  profile: state.profile,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch)
});

export const CommunityEditModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityEditModalComponent);

export default CommunityEditModal;
