import { GET_API_URL } from '../auth/authConfig';
import { IAuthToken } from '../controllers/AuthController/Auth.types';

type FetchOptionsType = {
    method: string,
    formData: string | FormData,
    additionalHeaders: any,
    extraOptions: any,
    isFileUpload: boolean
};

type FormDataType = FormData | string | any;

export const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

/**
 * This function handles error in making API
 * @param response
 * @returns {Promise<{ok}|*>}
 */
export const fetchHandleErrors = async response => {
    if (!response.ok) {
        const apiMessage = await response.text();

        // If we received something from server which contains `code` && `message` node || errors node - It must be JSON
        if (typeof apiMessage === 'string' && ((apiMessage.search('code') && apiMessage.search('message')) || apiMessage.search('"errors":')) ) {
            throw JSON.parse(apiMessage);
        }

        throw new Error(`${response.status} - ${response.statusText}`);
    }
    return response;
};

/**
 * Common fetch options
 * @param method
 * @param formData
 * @param additionalHeaders
 * @param extraOptions
 * @param isFileUpload
 * @returns {{headers: {Accept: string, 'Content-Type': string}, method: string, credentials: string, body: (string|null)}}
 */
export const fetchOptions = ({ method = 'GET', formData = null, additionalHeaders = {}, extraOptions = {}, isFileUpload = false }: FetchOptionsType) => {

    let data: FormData | string = formData ? JSON.stringify(formData) : null;

    if ( isFileUpload ) {
        // Use form data object because we will upload file
        const uploadData = new FormData();

        // Add form fields to upload Data
        Object.keys(formData).forEach( key => {
            uploadData.append(`${key}`, formData[key]);
        });

        // Assign back
        data = uploadData;
    }


    const options = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...additionalHeaders,
        },
        // credentials: 'include',
        body: data,
        ...extraOptions,
    };

    // Content type header should be omitted in-case of upload - multipart header is calculated and added by browser
    if ( isFileUpload )
        delete options.headers['Content-Type'];

    return options;
};

/**
 * Sends a HTTP query and handles error
 * @param url
 * @param method
 * @param formData
 * @param additionalHeaders
 * @param extraOptions
 * @param isFileUpload
 */
const httpServiceFuncRaw = ({url, method = 'GET', formData = null, additionalHeaders = {}, extraOptions = {}, isFileUpload = false}) => {
    if ( !url ) throw Error('Cannot fetch data due to empty URL');

    return fetch(`${GET_API_URL()}${url}`, fetchOptions({ method, formData, additionalHeaders, extraOptions, isFileUpload }))
        .then(fetchHandleErrors);
};

/**
 * Sends a HTTP query and returns JSON response
 * @param url
 * @param method
 * @param formData
 * @param additionalHeaders
 * @param extraOptions
 * @param isFileUpload
 */
const httpServiceFuncJson = ({url, method = 'GET', formData = null, additionalHeaders = {}, extraOptions = {}, isFileUpload = false}) => {
    if ( !url ) throw Error('Cannot fetch data due to empty URL');

    return httpServiceFuncRaw({url, method, formData, additionalHeaders, extraOptions, isFileUpload})
        .then(async response => {
            // API must be returning something for GET method
            if ( method === 'GET' )
                return response.json();

            // No content
            if ( response.status === 204 )
                return null;

            // API returns empty response to even post/put/delete
            const responseText = await response.text();
            if ( responseText === null || responseText === '' )
                return null;

            // Return JSON
            return JSON.parse(responseText);
        });
};

export const httpServiceGet = (url: string) => {
    return httpServiceFuncJson({url, method: 'GET'});
};

export const httpServicePost = (url: string, formData: FormData|string, ...otherOptions: any[]) => {
    const isFileUpload = otherOptions.length ? otherOptions[0].isFileUpload:false;
    return httpServiceFuncJson({url, method: 'POST', formData, isFileUpload });
};

export const httpServicePatch = (url: string, formData: FormData|string) => {
    return httpServiceFuncJson({url, method: 'PATCH', formData});
};

export const httpServiceSecureGet = (url: string, token: IAuthToken) => {
    if ( !token || !token.accessToken ) {
        console.error('Access token empty. Cannot call API');
        return Promise.reject(new Error('Error calling API'));
    }

    return httpServiceFuncJson({
        url: url,
        method: 'GET',
        additionalHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
        }
    });
};

export const httpServiceSecurePost = (url: string, token: IAuthToken, formData?: FormDataType) => {
    if ( !token || !token.accessToken ) {
        console.error('Access token empty. Cannot post data to API');
        return Promise.reject(new Error('Error calling API'));
    }

    return httpServiceFuncJson({
        url: url,
        formData: formData,
        method: 'POST',
        additionalHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
        }
    });
};

export const httpServiceSecurePut = (url: string, token: IAuthToken, formData?: FormDataType) => {
    if ( !token || !token.accessToken ) {
        console.error('Access token empty. Cannot put data to API');
        return Promise.reject(new Error('Error calling API'));
    }

    return httpServiceFuncJson({
        url: url,
        formData: formData,
        method: 'PUT',
        additionalHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
        }
    });
};

export const httpServiceSecureDelete = (url: string, token: IAuthToken) => {
    if ( !token || !token.accessToken ) {
        console.error('Access token empty. Cannot delete data in API');
        return Promise.reject(new Error('Error calling API'));
    }

    return httpServiceFuncJson({
        url: url,
        method: 'DELETE',
        additionalHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
        }
    });
};

/**
 * This function does not parse JSON but instead returns HTTP response object after GET.
 * @param url
 * @param token
 * @param headers
 */
export const httpServiceSecureGetRaw = (url: string, token: IAuthToken, headers?: any) => {
    if ( !url )
        Promise.reject(new Error('Cannot fetch data due to empty URL'));

    if ( !token || !token.accessToken )
        return Promise.reject(new Error('Error calling API'));

    const _headers = headers ? headers:{};
    return httpServiceFuncRaw({
        url: url,
        method: 'GET',
        additionalHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
            ..._headers,
        }
    });
};

/**
 * This function does not parse JSON but instead returns HTTP response object after POST.
 * @param url
 * @param token
 * @param formData
 * @param headers
 */
export const httpServiceSecurePostRaw = (url: string, token: IAuthToken, formData?: FormDataType, headers?: any) => {
    if ( !url )
        Promise.reject(new Error('Cannot fetch data due to empty URL'));

    if ( !token || !token.accessToken )
        return Promise.reject(new Error('Error calling API'));

    const _headers = headers ? headers:{};
    return httpServiceFuncRaw({
        url: url,
        formData,
        method: 'POST',
        additionalHeaders: {
            Authorization: `Bearer ${token.accessToken}`,
            ..._headers,
        }
    });
};