import * as React from 'react';
/**
 * React Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../controllers/ProfileController';

//UI Fabric
import { Label, ValidationState, TagPicker as UIFabricTagPicker } from '@fluentui/react';

export class DepartmentPickerComponent extends React.Component<any, any> {
  private tagPicker;

  constructor(props) {
    super(props);

    this.state = {
      selectedDepartment: []
    };
  }

  public render() {
    const { label, disabled, input, required } = this.props;

    return (
      <React.Fragment>
        <div>
          <Label>{label}</Label>
          <UIFabricTagPicker
            {...input}
            componentRef={ref => (this.tagPicker = ref)}
            pickerSuggestionsProps={{
              suggestionsHeaderText: 'Suggested departments',
              noResultsFoundText: 'No departments found'
            }}
            disabled={disabled}
            required={required}
            onResolveSuggestions={this.onResolveSuggestions}
            onChange={items => {
              input.onChange(items);

              this.setState({
                selectedDepartment: items
              });
            }}
            itemLimit={1}
            selectedItems={this.state.selectedDepartment}
            createGenericItem={this.createGenericItem}
            onValidateInput={this.onValidateInput}
          />
        </div>
      </React.Fragment>
    );
  }

  public componentDidMount() {
    if (this.props.profile.govTeamsProfileInfo) {
      this.setState({
        selectedDepartment: [
          {
            id: this.props.profile.govTeamsProfileInfo.department.id,
            name: this.props.profile.govTeamsProfileInfo.department.name
          }
        ]
      });
    }
  }

  public componentDidUpdate(prevProps) {
    if (
      !prevProps.profile.govTeamsProfileInfo &&
      this.props.profile.govTeamsProfileInfo
    ) {
      this.setState({
        selectedDepartment: [
          {
            id: this.props.profile.govTeamsProfileInfo.department.id,
            name: this.props.profile.govTeamsProfileInfo.department.name
          }
        ]
      });
    }
  }

  private onResolveSuggestions = async (filterText, currentTags) => {
    if (filterText) {
      const regEx = new RegExp(filterText, 'i');
      const filteredDepartments = this.props.departments
        ? this.props.departments.filter(item => {
            return regEx.test(item.name);
          })
        : [];

      const departments = filteredDepartments.map(department => {
        return {
          id: department.id,
          name: department.name
        };
      });
      return departments;
    } else {
      return [];
    }
  };

  private listContainsTags = (tag, currentTags): any => {
    if (!currentTags || !currentTags.length || currentTags.length === 0) {
      return false;
    }

    return (
      currentTags.filter(compareTag => compareTag.name === tag.name).length > 0
    );
  };

  private onValidateInput = (name: string) => {
    const tag = {
      name
    };

    return this.listContainsTags(tag, this.tagPicker.selection._items);
  };

  private createGenericItem = (name: string, isValid: ValidationState) => {
    const tagName: string = name.trim();

    if (isValid || name === '') {
      return null;
    }

    const newTag: any = {
      id: 0,
      name: tagName
    };

    return newTag;
  };
}

const mapStateToProps = state => ({
  profile: state.profile,
  departments: state.department.departments
});

const mapDispatchToProps = dispatch => ({
  profileActions: bindActionCreators(profileActionCreators, dispatch)
});

export const DepartmentPicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentPickerComponent);

export default DepartmentPicker;
