/**
 * Reserve licence
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { replace } from 'react-router-redux';
import { bindActionCreators } from 'redux';

/**
 * React form
 */
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

/**
 * Fabric UI
 */
import {
  ActionButton,
  PrimaryButton,
  CommandBarButton,
  DefaultButton,
} from '@fluentui/react/lib/Button';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  IColumn,
} from '@fluentui/react/lib/DetailsList';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Selection } from '@fluentui/react/lib/Selection';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';

/**
 * React form field
 */
import TextField from '../../../components/ReduxFormFields/TextField';

/**
 * Components
 */
import {
  organisationActionCreators,
  IOrganisationState,
  IOrganisationProps,
  IOrganisationMemberSearchParams,
} from '../../../controllers/OrganisationController';
import { MembersActionResults } from './MembersActionResults';
import * as constants from '../../../common/Constants';
import { selectionActions } from './../OrganisationMembers.constants';
import { ListPager } from './../components/ListPager';

/**
 * Utilities
 */
import { sortItems } from '../../../common/Utils';
import { BRANDNAME } from '../../../common/Tooltips';

const tooltipCommandBarButton: any = (props: any) => {
  const { defaultRender: DefaultRender = CommandBarButton } = props;

  return (
    <TooltipHost
      content={props.ariaLabel}
      directionalHint={DirectionalHint.topAutoEdge}
    >
      <DefaultRender {...props} />
    </TooltipHost>
  );
};

interface IMembersLicencesReserveState {
  hideResultsDialog: boolean;
  hideAddDialog: boolean;
  hideConfirmDialog: boolean;
  columns: IColumn[];
  items: any[];
  searchWord: string;
  searchCleared: boolean;
  selectedEmails: string[];
  selectedAction: string;
}

type IMergedProps = IOrganisationState &
  IOrganisationProps &
  RouteComponentProps &
  InjectedFormProps;

export class MembersLicencesReserveComponent extends React.Component<
  IMergedProps,
  IMembersLicencesReserveState
> {
  private selection: Selection;

  constructor(props) {
    super(props);

    const columns: IColumn[] = [
      {
        key: 'email',
        name: 'Email',
        fieldName: 'email',
        minWidth: 0,
        onColumnClick: this.onColumnClick,
      },
    ];

    this.selection = new Selection({
      onSelectionChanged: () => {
        this.getSelectedEmails();
      },
    });

    this.state = {
      hideResultsDialog: true,
      hideAddDialog: true,
      hideConfirmDialog: true,
      columns,
      items: [],
      searchWord: '',
      searchCleared: false,
      selectedEmails: [],
      selectedAction: '',
    };
  }

  public render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div className='orgMembersManagement-page'>
        <ActionButton
          className='govTeams-actionButton'
          onClick={(event: any) => {
            this.props.organisationActionCreators.resetLicencesForm();
            this.props.dispatch(
              replace(
                `${constants.SCREEN_ORGANISATION}/${this.props.match.params['organisationId']}`
              )
            );
          }}
          iconProps={{ iconName: 'ChevronLeft' }}
        >
          Back to organisation
        </ActionButton>

        <React.Fragment>
          <div className='topSection'>
            <h1>Reserve licence allocation</h1>
            <hr />
            <p>
              Reserve a licence for users that require access to {BRANDNAME} without your approval, by adding them to the list below.
            </p>
          </div>
          <div className='searchRow'>
            <div className='searchBox-col'>
              <div className='searchBox'>
                <SearchBox
                  placeholder='Search list'
                  value={this.state.searchWord}
                  onSearch={(newSearchWord: string) => {
                    this.onSearch();
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>, newSearchWord: string) => {
                    const newValue = e.target.value;
                    this.setState({
                      searchWord: newValue,
                    });
                  }}
                  onClear={(event: any) => {
                    this.setState({
                      searchWord: '',
                      searchCleared: true,
                    });
                  }}
                />
                <PrimaryButton text='Search' onClick={this.onSearch} />
              </div>
            </div>
          </div>
          <CommandBar
            className='govTeams-CommandBar'
            items={[]}
            farItems={this.getCommandBarFarItems()}
          />
          <MarqueeSelection selection={this.selection}>
            <DetailsList
              columns={this.state.columns}
              constrainMode={ConstrainMode.unconstrained}
              items={this.state.items}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.multiple}
              selection={this.selection}
            />
          </MarqueeSelection>
          {this.props.membersReserved &&
            this.props.membersReserved.totalPages > 0 && (
              <ListPager
                currentPage={this.props.membersReserved.pageNumber}
                pageSize={5}
                maxPages={this.props.membersReserved.totalPages}
                onPage={this.onPage}
                onNext={this.onNext}
                onPrevious={this.onPrevious}
              />
            )}
        </React.Fragment>

        <Dialog
          minWidth='600px'
          hidden={this.state.hideAddDialog}
          onDismiss={this.hideAddDialog}
          modalProps={{ className: 'govTeams-dialog' }}
        >
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <h2>Reserve licence allocation</h2>
            <p>
              Reserve a licence for a user in your organisation by entering
              their email address below. To reserve licences for multiple users,
              separate each email address with a semicolon or a new line.
            </p>
            <div className='formFields'>
              <div className='formField'>
                <Field
                  aria-labelledby='members-email'
                  name='emails'
                  component={TextField}
                  placeholder="Enter members' email"
                  multiline={true}
                  rows='10'
                />
              </div>
            </div>
            <div className='formFooter'>
              <DefaultButton text='Cancel' onClick={this.hideAddDialog} />
              <PrimaryButton
                text='Save'
                type='submit'
                disabled={submitting}
                className='buttonSpacing'
              />
            </div>
          </form>
        </Dialog>
        <Dialog
          minWidth='600px'
          hidden={this.state.hideResultsDialog}
          onDismiss={this.hideResultsDialog}
          modalProps={{ className: 'govTeams-dialog-results' }}
        >
          {this.props.membersActionResult && (
            <React.Fragment>
              <h2
                className={`h1 heading-${this.props.membersActionResult.code.toLowerCase()}`}
              >
                {this.props.membersActionResult.code}
              </h2>
              <p>{this.props.membersActionResult.message}</p>
              <div className='spacing-bottom' />
              {this.props.membersActionResult.result.length > 0 && (
                <MembersActionResults
                  results={this.props.membersActionResult}
                />
              )}
            </React.Fragment>
          )}
          <DialogFooter>
            <PrimaryButton onClick={this.hideResultsDialog} text='Ok' />
          </DialogFooter>
        </Dialog>

        <Dialog
          minWidth='600px'
          hidden={this.state.hideConfirmDialog}
          onDismiss={this.hideConfirmDialog}
          modalProps={{ className: 'govTeams-dialog' }}
        >
          <React.Fragment>
            <p>
              Are you sure you want to {this.state.selectedAction} these users
              from the list?
            </p>
          </React.Fragment>
          <DialogFooter>
            <DefaultButton text='Cancel' onClick={this.hideConfirmDialog} />{' '}
            <PrimaryButton
              text='Yes, continue'
              onClick={() => {
                switch (this.state.selectedAction) {
                  case selectionActions.add:
                    this.props.organisationActionCreators.reserveLicences(
                      this.props.match.params['organisationId']
                    );
                    break;
                  case selectionActions.remove:
                    this.props.organisationActionCreators.reserveLicences(
                      this.props.match.params['organisationId'],
                      true,
                      this.state.selectedEmails
                    );
                    break;
                  default:
                    break;
                }
              }}
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }

  public componentDidMount() {
    this.init(this.props.match.params['organisationId']);
  }

  public async componentDidUpdate(prevProps, prevState) {
    if (prevProps.membersReserved !== this.props.membersReserved) {
      if (this.props.membersReserved) {
        const emails = this.props.membersReserved.emailAddress.map(
          (email: string) => {
            return {
              email,
            };
          }
        );
        this.setState({
          items: emails,
        });
      }
    }

    if (!prevProps.isFormSubmitted && this.props.isFormSubmitted) {
      this.setState({
        hideResultsDialog: false,
        hideConfirmDialog: true,
        hideAddDialog: true,
      });
    }

    if (prevState.hideResultsDialog && !this.state.hideResultsDialog) {
      this.props.reset();
      this.init(this.props.match.params['organisationId']);
    }

    if (this.state.searchWord === '' && this.state.searchCleared) {
      await this.onSearch();

      this.setState({
        searchCleared: false,
      });
    }
  }

  private async init(organisationId: string) {
    await this.props.organisationActionCreators.requestFetchReservedMembers(
      organisationId
    );

    this.props.initialize('');

    if (this.props.membersReserved) {
      const emails = this.props.membersReserved.emailAddress.map(
        (email: string) => {
          return {
            email,
          };
        }
      );

      this.setState({
        items: emails,
      });
    }
  }

  private onSubmit = (event) => {
    this.setState({
      hideAddDialog: true,
      hideConfirmDialog: false,
    });
  };

  private hideResultsDialog = async () => {
    this.setState({
      hideResultsDialog: true,
    });
  };

  private hideAddDialog = () => {
    this.setState({
      hideAddDialog: true,
    });
  };

  private hideConfirmDialog = () => {
    this.setState({
      hideConfirmDialog: true,
    });
  };

  private getCommandBarFarItems = () => {
    return [
      {
        key: 'delete',
        name: 'Remove from list',
        ariaLabel: 'Remove from reserved list',
        iconProps: {
          iconName: 'Delete',
        },
        disabled:
          this.state.selectedEmails && this.state.selectedEmails.length === 0,
        commandBarButtonAs: tooltipCommandBarButton,
        onClick: () => {
          this.setState({
            hideConfirmDialog: false,
            selectedAction: selectionActions.remove,
          });
        },
      },
      {
        key: 'add',
        name: 'Add to list',
        ariaLabel: 'Add to reserved list',
        className: 'commandBar-primaryButton',
        disabled: !this.props.match.params['organisationId'] ? true : false,
        iconProps: {
          iconName: 'CircleAddition',
        },
        commandBarButtonAs: tooltipCommandBarButton,
        onClick: () => {
          this.setState({
            hideAddDialog: false,
            selectedAction: selectionActions.add,
          });
        },
      },
    ];
  };

  private getSelectedEmails = () => {
    const selectedEmails = this.selection.getSelection().map((item: any) => {
      return item.email;
    });

    this.setState({
      selectedEmails,
    });
  };

  private onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ) => {
    const { columns, items } = this.state;

    let newItems = items.slice();
    const newColumns: IColumn[] = columns.slice();

    const currColumn = newColumns.filter((currCol, idx) => {
      return column.key === currCol.key;
    })[0];

    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    });

    newItems = sortItems(
      newItems,
      currColumn.fieldName,
      currColumn.isSortedDescending
    );

    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private onSearch = () => {
    const params: IOrganisationMemberSearchParams = {
      searchWord: this.state.searchWord,
      pageNumber: 1,
    };

    this.props.organisationActionCreators.requestFetchReservedMembers(
      this.props.match.params['organisationId'],
      params
    );
  };

  private onPage = (event) => {
    this.props.organisationActionCreators.requestFetchReservedMembers(
      this.props.match.params['organisationId'],
      {
        searchWord: this.state.searchWord,
        pageNumber: event.currentTarget.dataset.page,
      }
    );
  };

  private onPrevious = (event) => {
    this.props.organisationActionCreators.requestFetchReservedMembers(
      this.props.match.params['organisationId'],
      {
        searchWord: this.state.searchWord,
        pageNumber:
          this.props.membersReserved &&
          this.props.membersReserved.pageNumber - 1,
      }
    );
  };

  private onNext = (event) => {
    this.props.organisationActionCreators.requestFetchReservedMembers(
      this.props.match.params['organisationId'],
      {
        searchWord: this.state.searchWord,
        pageNumber:
          this.props.membersReserved &&
          this.props.membersReserved.pageNumber + 1,
      }
    );
  };
}

const mapStateToProps = (state) => ({
  membersReserved: state.organisation.membersReserved,
  isFormSubmitted: state.organisation.isFormSubmitted,
  membersActionResult: state.organisation.membersActionResult,
});

const mapDispatchToProps = (dispatch) => ({
  organisationActionCreators: bindActionCreators(
    organisationActionCreators,
    dispatch
  ),
  dispatch,
});

export const MembersLicencesReserveForm = reduxForm({
  form: 'MembersLicencesReserveForm',
})(MembersLicencesReserveComponent);

export const MembersLicencesReserve = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersLicencesReserveForm);

export default MembersLicencesReserve;
