/**
 * React Redux
 */
import * as React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../controllers/ProfileController';
import { memberPreferencesActionCreators } from '../controllers/MemberPreferencesController';

/**
 * Fabric UI
 */

import { initializeFileTypeIcons } from '@uifabric/file-type-icons';

import { Fabric } from '@fluentui/react/lib/Fabric';
import { Link } from '@fluentui/react/lib/Link';
import {
  MessageBar,
  MessageBarType
} from '@fluentui/react/lib/MessageBar';
import {
  Shimmer,
  ShimmerElementType
} from '@fluentui/react/lib/Shimmer';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
/**
 * GovTEAMS custom components
 */
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Notification from '../components/Notification';
import Announcements from '../components/Announcements';
import VerificationBanner from '../components/Header/VerificationBanner';
import Progress from '../components/Progress';
import JoinRequest from '../components/ReduxFormFields/JoinRequest';
import InviteMembersModal from '../components/InviteMembersModal';
import UserAccessDialog from './Profile/UserAccessDialog';
import { MemberStatus } from '../controllers/CommunityController';

import * as constants from './../common/Constants';

/**
 * Utilities
 */
import { isProtected } from './../common/Utils';


initializeFileTypeIcons();

export class Layout extends React.Component<any, any> {

  public render() {
    /**
     * Create a body class to target page specific styles
     */
    const pathname = window.location.pathname;
    const paths = pathname.split('/');
    const bodyClass = paths ? 'govTeams-page' + paths[paths.length - 1] : '';

    return (
      <Fabric>
        <div
          className={`govTeams-App-wrapper${
            isProtected() ? ' govTeams-protected' : ''
          }`}
        >
          <div className='govTeams-header'>
            {this.props.isLoading && (
              <ProgressIndicator
                barHeight={3}
                styles={{
                  root: {
                    marginTop: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 5
                  },
                  itemProgress: {
                    paddingTop: 0,
                    paddingBottom: 0
                  },
                  itemName: {
                    display: 'none'
                  },
                  itemDescription: {
                    display: 'none'
                  }
                }}
              />
            )}
            <Header />

            {this.props.isAuthenticated && (
              <React.Fragment>
                <Progress />
                <JoinRequest />
                <UserAccessDialog />
                <Notification />
              </React.Fragment>
            )}
          </div>
          <div className={`govTeams-pageContainer ${bodyClass}`}>
            {this.props.isAuthenticated ? (
              <React.Fragment>
                {!this.props.isAuthorised && this.props.govTeamsProfileInfo && (
                  <MessageBar
                    messageBarType={MessageBarType.info}
                    isMultiline={true}
                  >
                    We are working on making this screen better for you! Let us
                    know if you have any feedback on how we can improve the
                    experience via the{' '}
                    <Link
                      href='https://www.govteams.gov.au/provide-feedback'
                      target='_blank'
                    >
                      Feedback form
                    </Link>
                  </MessageBar>
                )}
                <Announcements />
                <VerificationBanner />
                <InviteMembersModal />
                {this.props.govTeamsProfileInfo && this.props.preferences ? (
                  <div className='govTeams-content'>{this.props.children}</div>
                ) : (
                  <div className='govTeams-content'>
                    <Shimmer
                      styles={{
                        root: {
                          marginTop: 40,
                          marginBottom: 40
                        }
                      }}
                      shimmerElements={[
                        {
                          type: ShimmerElementType.line,
                          width: '100%',
                          height: 46
                        }
                      ]}
                    />
                    <Shimmer
                      shimmerColors={{
                        background: '#f8f8f8'
                      }}
                      shimmerElements={[
                        {
                          type: ShimmerElementType.line,
                          width: '19%',
                          height: 600
                        },
                        {
                          type: ShimmerElementType.gap,
                          width: '3%',
                          height: 600
                        },
                        {
                          type: ShimmerElementType.line,
                          width: '78%',
                          height: 600
                        }
                      ]}
                    />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div className='govTeams-content'>
                <ProgressIndicator
                  label='Signing you in.'
                  description="This won't take long..."
                  styles={{
                    root: {
                      marginTop: 10
                    },
                    itemName: {
                      fontSize: 24,
                      lineHeight: '1.6em'
                    },
                    itemDescription: {
                      fontSize: 14
                    }
                  }}
                />
              </div>
            )}
          </div>
          <Footer />
        </div>
      </Fabric>
    );
  }

    public async componentDidMount() {
        localStorage.setItem('expandedView', 'false');
        await this.props.profile.requestAuthentication();
        await this.props.profile.requestProfile();
        await this.props.memberPreferencesActionCreators.fetchMemberPreferences();
    } 

  public componentDidUpdate(prevProps: any) {
    const currProps: any = this.props;

    if (
      currProps.children.props.location.pathname !==
      prevProps.children.props.location.pathname
    ) {
      window.scrollTo(0, 0);
    }

    if (currProps.memberStatus !== prevProps.memberStatus) {
      const paths = currProps.children.props.location.pathname.split('/');

      if (
        currProps.memberStatus === MemberStatus.PendingLicence &&
        (`/${paths[1]}` !== constants.SCREEN_ORGANISATION ||
          `/${paths[1]}` !== constants.SCREEN_PROFILE)
      ) {
        currProps.dispatch(push(constants.SCREEN_PENDING));
      }

      if (
        currProps.memberStatus === MemberStatus.Blocked &&
        (`/${paths[1]}` !== constants.SCREEN_ORGANISATION ||
          `/${paths[1]}` !== constants.SCREEN_PROFILE)
      ) {
        currProps.dispatch(push(constants.SCREEN_BLOCKED));
      }

      // if (currProps.memberStatus === MemberStatus.ApprovalPending) {
      //   currProps.dispatch(push(constants.SCREEN_APPROVAL_PENDING));
      // }
    }
  }
}

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.profile.isAuthenticated,
  isAuthorised: state.profile.isAuthorised,
  memberStatus: state.profile.memberStatus,
  govTeamsProfileInfo: state.profile.govTeamsProfileInfo,
    isLoading: state.progress.isLoading,
  preferences: state.memberPreferences.preferences
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  profile: bindActionCreators(profileActionCreators, dispatch),
  memberPreferencesActionCreators: bindActionCreators(memberPreferencesActionCreators, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
